import React, {useEffect, useState} from "react";
import AboutMe from "./AboutMe";
import AdditionalLinks from "./AdditionalLinks";
import CompanyStages from "./CompanyStages";
import EngagementFlexibility from "./EngagementFlexibility";
import Header from "./Header";
import Industries from "./Industries";
import Projects from "./Projects";
import Skills from "./Skills";
import Tools from "./Tools";
import {Loader} from "../onboard/screens";
import CompletenessWizard from "./CompletenessWizard";
import {useHistory} from "react-router-dom";
import {getUrlSuffix} from "../../../utils/localStorageService";

const ProfilePage = ({ talentData, setTalentData, isPublicProfile }) => {
  const [completenessWizardHandlers, setCompletenessWizardHandlers] = useState({});
  const [onAboutMeClick, setOnAboutMeClick] = useState(() => {});
  const [onProjectsClick, setOnProjectsClick] = useState(() => {});
  const [onProfilePhotoClick, setOnProfilePhotoClick] = useState(() => {});

  const history = useHistory();

  const userSuffix = getUrlSuffix();

  useEffect(() => {
    setCompletenessWizardHandlers({
    onAboutMeClick,
    onProjectsClick,
    onProfilePhotoClick,
    onSkillRatingsClick: () => history.push(`/talent/${userSuffix}/niche?tab=skills`),
    onToolRatingsClick: () => history.push(`/talent/${userSuffix}/niche?tab=tools`),
  });
  }, [onAboutMeClick]);
  return (
    <div className="w-full h-full">
      {talentData ?
        <div className="flex p-5">
          <div className="flex flex-col w-2/3 pr-5 gap-4">
            <Header talentData={talentData} setTalentData={setTalentData} isPublicProfile={isPublicProfile} setEditProfilePhoto={setOnProfilePhotoClick} />
            <AboutMe talentData={talentData} setTalentData={setTalentData} isPublicProfile={isPublicProfile} setOpenAboutMe={setOnAboutMeClick} />
            <Projects talentData={talentData} setTalentData={setTalentData} isPublicProfile={isPublicProfile} setAddNewProject={setOnProjectsClick} />
            <Skills talentData={talentData} isPublicProfile={isPublicProfile} />
            <Tools talentData={talentData} isPublicProfile={isPublicProfile} />
          </div>
          <div className="flex flex-col w-1/3 gap-4 text-midnight">
            <CompletenessWizard talentData={talentData} isPublicProfile={isPublicProfile} onClickHandlers={completenessWizardHandlers} />
            <EngagementFlexibility talentData={talentData} isPublicProfile={isPublicProfile} />
            <AdditionalLinks talentData={talentData} isPublicProfile={isPublicProfile} />
            <Industries talentData={talentData} isPublicProfile={isPublicProfile} />
            <CompanyStages talentData={talentData} isPublicProfile={isPublicProfile} />
          </div>
        </div> :
        <Loader bgColor="bg-lightest-grey" />
      }
    </div>
  );
};

export default ProfilePage;
