import React, {useMemo} from "react";
import {EditBtn} from "components/Buttons";
import {
  QualificationsDisplay
} from "../../../components/QualificationsDisplay";

const CompanyStages = ({ talentData, setTalentData, isPublicProfile }) => {
  const { qualifications } = talentData;

  const stages = useMemo(() => {
    if (!qualifications) return;
    return qualifications.filter(
      q => q.qualification?.type.toLowerCase() === 'stage' &&
      (!isPublicProfile || !!q.display_on_talent_profile)
    ).map(q => q.qualification);
  }, [qualifications, isPublicProfile]);

  return (
    <div className="bg-white rounded-lg p-4 gap-y-3.5 flex flex-col">
      <div className="flex gap-2 items-center">
        <div className="text-lg font-bold">Company Stages</div>
        {!isPublicProfile && <div className="ml-auto mr-0">
          <EditBtn />
        </div>}
      </div>
      <div className="text-sm flex gap-4">
        {stages && <QualificationsDisplay qualifications={stages} large />}
      </div>
    </div>
  )
};

export default CompanyStages;
