import React, {useState} from "react";
import clsx from "clsx";
import Modal from "../components/Modal";
import $ from "strings/talent";
import {Form, Formik} from "formik";
import * as yup from "yup";
import {Input, Textarea} from "../../../components/Form";
import {FormErrorMessage, Label} from "../components";
import AlertError from "../../../components/AlertError";
import {useAlert} from "react-alert";
import {postTalentLead} from "../../../utils/api";


const FORM_CONFIG = [
  {
    name: 'first_name',
    label: $.wwform_first_name_label,
    placeholder: $.wwform_first_name_placeholder,
    type: 'text',
    component: Input,
    wrapperClass: "w-1/2 pr-1",
  },
  {
    name: 'last_name',
    label: $.wwform_last_name_label,
    placeholder: $.wwform_last_name_placeholder,
    type: 'text',
    component: Input,
    wrapperClass: "w-1/2 pl-1",
  },
  {
    name: 'email',
    label: $.wwform_email_label,
    placeholder: $.wwform_email_placeholder,
    type: 'email',
    component: Input,
  },
  {
    name: 'company_name',
    label: $.wwform_company_name_label,
    placeholder: $.wwform_company_name_placeholder,
    type: 'text',
    component: Input,
  },
  {
    name: 'company_website',
    label: $.wwform_company_website_label,
    placeholder: $.wwform_company_website_placeholder,
    type: 'text',
    component: Input,
  },
  {
    name: 'description',
    label: $.wwform_message_label,
    placeholder: $.wwform_message_placeholder,
    type: 'textarea',
    component: Textarea,
    className: 'resize-none',
  }
];

const WorkWithForm = ({
  onClose,
  talentData,
  setTalentData
}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const alert = useAlert();

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    company_name: '',
    company_website: '',
    description: '',
  };

  const validationSchema = yup.object().shape({
    first_name: yup.string().required($.wwform_first_name_required),
    last_name: yup.string().required($.wwform_last_name_required),
    email: yup.string().email($.wwform_email_invalid).required($.wwform_email_required),
    company_name: yup.string().required($.wwform_company_name_required),
    company_website: yup.string().url($.wwform_company_website_invalid).required($.wwform_company_website_required),
    description: yup.string().nullable(),
  });

  return (
    <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={() => {}}
        enableReinitialize={true}
      >
        {({
          setFieldValue,
          values,
          touched,
          setTouched,
          setFieldTouched,
          errors,
          setFieldError,
          validateForm
        }) => {
          const onClickSave = async () => {
            setIsUpdating(true);
            try {
              let company_website = values.company_website;
              if (company_website && !company_website.startsWith('http')) {
                company_website = `https://${company_website}`;
                await setFieldValue('company_website', company_website);
              }
              const err = await validateForm();
              if (Object.keys(err).length) {
                setIsUpdating(false);
                return;
              }
              const payload = {
                ...values,
                company_website,
                talent_id: talentData?.user?.id,
              };
              await postTalentLead(payload);
              setIsUpdating(false);
              onClose();
            } catch (e) {
              alert.error(<AlertError error={e} />);
              setIsUpdating(false);
            }
          };

          return (
            <Modal
              title={`${$.work_with_btn} ${talentData?.user?.first_name}`}
              onClose={onClose}
              onClickCancel={onClose}
              onClickSave={onClickSave}
              buttonsDisabled={isUpdating}
              saveBtnText={$.send_btn}
              showDelete={false}
              id="profile_photo_form"
            >
              <Form className="form">
                <div className="flex flex-wrap gap-y-0.5">
                  {FORM_CONFIG.map((config, index) => {
                    const Component = config.component;
                    return (
                      <div className={clsx("flex flex-col gap-y-1", config.wrapperClass || "w-full")}>
                        <Label>{config.label}</Label>
                        <Component
                          name={config.name}
                          type={config.type}
                          value={values[config.name]}
                          error={errors[config.name]}
                          onChange={(e) => setFieldValue(config.name, e.target.value)}
                          onBlur={() => setFieldTouched(config.name, true)}
                          placeholder={config.placeholder}
                          className={config.className || ''}
                        />
                        <FormErrorMessage error={errors[config.name]} />
                      </div>
                    );
                  })}
                </div>
              </Form>
            </Modal>
          );
        }}
      </Formik>
  );
};

export default WorkWithForm;
