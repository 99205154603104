import React from "react";
import clsx from "clsx";
import $ from "strings/talent";
import {Checkbox, Input, Label, Sublabel} from "components/Form";
import {Error} from "../../components/index";
import {PhoneInput} from 'react-international-phone';

const COMMUNICATION_PREFERENCES_OPTIONS = [
  {label: $.email_option, value: 'email'},
  {label: $.text_option, value: 'text'},
  {label: $.phone_option, value: 'phone'}
]

const ContactInformation = ({
  setFieldValue,
  values,
  touched,
  errors,
  setFieldError,
  setFieldTouched
}) => {
  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    if (errors[name]) {
      setFieldError(name, null)
    }
  };

  const onCheckboxClick = (fieldName, option, value) => {
    const currentValues = values[fieldName];
    let currentValuesArr = currentValues?.length ? currentValues.split('|') : [];
    if (value === true && currentValuesArr.indexOf(option) === -1) {
      currentValuesArr.push(option);
      if (errors[fieldName]) {
        setFieldError(fieldName, null);
      }
    } else if (value === false && currentValuesArr.indexOf(option) > -1) {
      currentValuesArr.splice(currentValuesArr.indexOf(option), 1)
    }
    const newValues = currentValuesArr.sort().join('|')
    setFieldValue(fieldName, newValues);
  }

  const onChangePhone = (phone) => {
    setFieldValue('phone', phone);
    setFieldTouched('phone', true);
    if (errors['phone']) {
      setFieldError(phone, null)
    }
  }

  return (
    <div className="px-8 py-4 grow">
      <div className="py-1 mb-auto">
        <div className="text-darkest-navy text-2xl sm:text-3xl font-bold py-1">{$.contact_information_title}</div>
        <div className="text-midnight text-base sm:text-lg font-normal">{$.contact_information_subtitle}</div>
      </div>
      <div className="scrollableSurveyContent py-4">
        <div className="sm:grid sm:grid-cols-2 gap-2">
          <div className="my-px">
            <Label
              className="font-normal text-darkest-navy text-sm sm:text-base">{$.first_name_label}</Label>
            <Input
              name="firstName"
              maxLength="100"
              placeholder={$.first_name_placeholder}
              value={values.firstName}
              onChange={onChange}
              error={errors.firstName}
              fontSize={'text-sm sm:text-base'}
            />
            {(!touched.firstName && errors.firstName) ?
              <Error msg={errors.firstName}/> :
              <div style={{height: '22px'}}/>}
          </div>
          <div className="my-px">
            <Label
              className="font-normal text-darkest-navy text-sm sm:text-base">{$.last_name_label}</Label>
            <Input
              name="lastName"
              maxLength="100"
              placeholder={$.last_name_placeholder}
              value={values.lastName}
              onChange={onChange}
              error={errors.lastName}
              fontSize={'text-sm sm:text-base'}
            />
            {(!touched.lastName && errors.lastName) ?
              <Error msg={errors.lastName}/> : <div style={{height: '22px'}}/>}
          </div>
          <div className="my-px">
            <Label
              className="font-normal text-darkest-navy text-sm sm:text-base">{$.scheduling_link_label}</Label>
            <Sublabel>{$.scheduling_link_sublabel}</Sublabel>
            <Input
              name="schedulingLink"
              maxLength="100"
              placeholder={$.scheduling_link_placeholder}
              onChange={onChange}
              fontSize={'text-sm sm:text-base'}
              value={values.schedulingLink}
              error={errors.schedulingLink}
            />
            {(!touched.schedulingLink && errors.schedulingLink) ?
              <Error msg={errors.schedulingLink}/> :
              <div style={{height: '22px'}}/>}
          </div>
          <div className="my-px">
            <Label
              className="font-normal text-darkest-navy text-sm sm:text-base">{$.phone_number_label}</Label>
            <Sublabel>{$.phone_number_sublabel}</Sublabel>
            <PhoneInput
              name="phone"
              className={clsx("text-base", (!touched.phone && errors.phone) ? "border-2 border-red" : "")}
              defaultCountry="us"
              preferredCountries={["us", "ca"]}
              forceDialCode
              placeholder="Enter your phone number"
              value={values.phone}
              onChange={(phone) => onChangePhone(phone)}
            />
            {(!touched.phone && errors.phone) ? <Error msg={errors.phone}/> :
              <div style={{height: '22px'}}/>}
          </div>
          <div className="my-px">
            <Label
              className="font-normal text-darkest-navy text-sm sm:text-base">{$.communication_preferences_label}</Label>
            <Sublabel>{$.select_all_sublabel}</Sublabel>
            {COMMUNICATION_PREFERENCES_OPTIONS.map((option) =>
              <Checkbox
                outlined
                key={option.value}
                value={values['communicationPreferences']?.includes(option.value) ? true : false}
                onChange={(v) => onCheckboxClick('communicationPreferences', option.value, v)}
              >
                <div
                  className="ml-1 my-1 text-sm text-darkest-navy font-lato">{option.label}</div>
              </Checkbox>
            )}
            {(!touched.communicationPreferences && errors.communicationPreferences) ?
              <Error msg={errors.communicationPreferences}/> :
              <div style={{height: '22px'}}/>}
          </div>
        </div>
        <div className="my-2 text-kasmir font-normal text-xs">
          <span dangerouslySetInnerHTML={{__html: $.terms_privacy_statement}}/>
        </div>
      </div>
    </div>
  )
};

export default ContactInformation;
