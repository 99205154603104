import React, {useState} from "react";
import * as yup from "yup";
import {FormErrorMessage, Label, Modal} from "../components";
import $ from "../../../strings/talent.json";
import {Form, Formik} from "formik";
import {Input, Textarea} from "../../../components/Form";


export const AddTalentProjectLinkModal = ({ initialValues, closeModal, saveLink, deleteLink }) => {
  const [isUpdating, setIsUpdating] = useState(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        yup.object().shape({
          url: yup.string().required('Required').url('Invalid URL'),
          name: yup.string().required('Required'),
        })}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={() => {}}
      enableReinitialize={true}
    >
      {({
        setFieldValue,
        values,
        touched,
        setTouched,
        setFieldTouched,
        errors,
        setFieldError,
        validateForm
      }) => {
        const onChange = async (e) => {
          let {name, value} = e.target;
          await setFieldValue(name, value);
          await setFieldTouched(name, value !== initialValues[name]);
          if (errors[name]) {
            setFieldError(name, null);
          }
        };

        const save = async () => {
          setIsUpdating(true);
          try {
            let url = values.url;
            if (!url.startsWith('http')) {
              url = `https://${url}`;
            }
            await setFieldValue('url', url);
            const validationErrors = await validateForm();
            if (Object.keys(validationErrors).length > 0) {
              return;
            }
            const link = {
              url: values.url,
              name: values.name,
              description: values.description,
            };
            if (initialValues.id) {
              link.id = initialValues.id;
            }
            saveLink(link);
            setIsUpdating(false);
            closeModal();
          } finally {
            setIsUpdating(false);
          }
        };

        return (
          <Modal
            title={$.add_url_title}
            onClose={() => {
              setIsUpdating(false);
              closeModal();
            }}
            onClickSave={save}
            buttonsDisabled={isUpdating}
            onClickDelete={() => {
              deleteLink({ ...values, id: initialValues.id });
              closeModal();
            }}
            showDelete={!!initialValues.id}
            deleteBtnText={$.delete_url_btn}
          >
            <Form className="form">
              <div className="flex flex-col">
                <div>
                  <Label>{$.add_url_label}</Label>
                  <Input
                    name="url"
                    value={values.url}
                    error={errors.url}
                    onChange={onChange}
                    placeholder={$.add_url_placeholder}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        save().then();
                      }
                    }}
                  />
                  <FormErrorMessage error={errors.url}/>
                </div>
                <div>
                  <Label>{$.add_url_title_label}</Label>
                  <Input
                    name="name"
                    value={values.name}
                    error={errors.name}
                    onChange={onChange}
                    placeholder={$.add_url_title_placeholder}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        save().then();
                      }
                    }}
                  />
                  <FormErrorMessage error={errors.name}/>
                </div>
                <div>
                  <Label>{$.add_url_description_label}</Label>
                  <Textarea
                    name="description"
                    value={values.description}
                    error={errors.description}
                    onChange={onChange}
                    placeholder={$.add_url_description_placeholder}
                    className="resize-none h-25"
                    unbolded
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        save().then();
                      }
                    }}
                  />
                </div>
              </div>
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
};
