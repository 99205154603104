import React, {useEffect, useState} from "react";
import $ from "strings/talent";
import {Checkbox, Radio} from "components/Form";
import {FormErrorMessage, Label, SettingsActionBar} from "../components";
import {Form, Formik} from "formik";
import {patchTalentTalent} from "../../../utils/api";
import AlertError from "../../../components/AlertError";
import * as yup from "yup";
import {useAlert} from "react-alert";

const FULLTIME_INTEREST_OPTIONS = [
  {label: $.yes_option, value: true},
  {label: $.no_option, value: false}
];

const ONSITE_FREQUENCY_OPTIONS = [
  {label: $.regular_days_option, value: 'regular'},
  {label: $.occasional_meetings_option, value: 'occasional'},
  {label: $.special_events_option, value: 'special'},
  {label: $.not_interested_travel_option, value: 'not_interested' }
];

const TRAVEL_DISTANCE_OPTIONS = [
  {label: $.local_travel_option, value: 'local'},
  {label: $.domestic_travel_option, value: 'domestic'},
  {label: $.international_travel_option, value: 'international'}
];

const OpportunityPreferences = ({ talentData, setTalentData }) => {
  const [userId, setUserId] = useState();
  const [initialValues, setInitialValues] = useState({
    fulltimeInterest: null,
    onsiteFrequency: null,
    travelDistancePreferences: null
  });
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    if (!talentData) {
      return;
    }
    setInitialValues({
      fulltimeInterest: talentData.fulltime_interest,
      onsiteFrequency: talentData.onsite_frequency,
      travelDistancePreferences: talentData.travel_distance_preferences
    });
    setUserId(talentData.user?.id);
  }, [talentData]);

  const alert = useAlert();

  const updateTalent = async (values) => {
    const data = {
      fulltime_interest: values.fulltimeInterest,
      onsite_frequency: values.onsiteFrequency,
      travel_distance_preferences: values.travelDistancePreferences
    };
    try {
      await patchTalentTalent(userId, data);
      setInitialValues(values);
    } catch (e) {
      console.error({ e, data });
      alert.error(<AlertError error="Something went wrong. Please try again, or contact us if the issue continues." />);
    }
  };

  const validationSchema = yup.object().shape({
    fulltimeInterest: yup
      .boolean()
      .required("Select an option"),
    onsiteFrequency: yup
      .string()
      .required("Select at least one option"),
    travelDistancePreferences: yup
      .string()
      .when(["onsiteFrequency"], {
        is: (onsiteFrequency) =>
          onsiteFrequency !== 'not_interested',
        then: schema => schema.required("Select at least one option"),
        otherwise: schema => schema.optional().nullable(true)
      })
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={() => {}}
      enableReinitialize={true}
    >
      {({
        setFieldValue,
        values,
        touched,
        setTouched,
        setFieldTouched,
        errors,
        setFieldError,
        validateForm
      }) => {
        const onCheckboxClick = async (fieldName, option, value) => {
          await setFieldTouched(fieldName, true);
          // if user selects Not Interested, override other selections
          if (value === true && option === 'not_interested') {
            await setFieldValue(fieldName, option);
            await setFieldTouched(fieldName, option !== initialValues[fieldName]);
            await setFieldValue('travelDistancePreferences', null);
            if (errors[fieldName]) {
              setFieldError(fieldName, null);
            }
            return;
          }
          const currentValues = values[fieldName];
          let currentValuesArr = currentValues?.length ? currentValues.split('|') : [];
          if (value === true && currentValuesArr.indexOf(option) === -1) {
            currentValuesArr.push(option);
            if (errors[fieldName]) {
              setFieldError(fieldName, null);
            }
            // if user selects an option other than Not Interested, remove Not Interested
            if (currentValuesArr.indexOf('not_interested') > -1) {
              currentValuesArr.splice(currentValuesArr.indexOf('not_interested'), 1)
            }
          } else if (value === false && currentValuesArr.indexOf(option) > -1) {
            currentValuesArr.splice(currentValuesArr.indexOf(option), 1)
          }
          // sort
          const newValues = currentValuesArr.sort().join('|');
          await setFieldValue(fieldName, newValues);
          await setFieldTouched(fieldName, newValues !== initialValues[fieldName]);
        };

        const onRadioClick = async (name, value) => {
          await setFieldValue(name, value);
          await setFieldTouched(name, value !== initialValues[name]);
          if (errors[name]) {
            setFieldError(name, null)
          }
        };

        return (
          <Form className="form">
            <div className="mb-2">
              <Label>{$.fulltime_interest_label}</Label>
              {FULLTIME_INTEREST_OPTIONS.map((option) =>
                <Radio
                  outlined
                  key={option.value}
                  name='fulltimeInterest'
                  value={option.value}
                  checked={values['fulltimeInterest'] === option.value}
                  onChange={() => onRadioClick('fulltimeInterest', option.value)}
                >
                 <div className="ml-1 my-1 font-sm text-darkest-navy font-lato">{option.label}</div>
               </Radio>
              )}
              <FormErrorMessage error={errors.fulltimeInterest} />
            </div>
            <div className="mb-2">
              <Label>{$.onsite_frequency_label}</Label>
              {ONSITE_FREQUENCY_OPTIONS.map((option) =>
                <Checkbox
                  outlined
                  key={option.value}
                  value={!!values['onsiteFrequency']?.includes(option.value)}
                  onChange={(v) => onCheckboxClick('onsiteFrequency', option.value, v)}
                  disabled={
                    values['onsiteFrequency']?.includes('not_interested' && option.value !== 'not_interested')
                  }
                >
                  <div className="ml-1 my-1 font-sm text-darkest-navy font-lato">{option.label}</div>
                </Checkbox>
              )}
              <FormErrorMessage error={errors.onsiteFrequency} />
            </div>
            {values['onsiteFrequency'] !== 'not_interested' && (
              <div className="mb-2">
                <Label>{$.travel_distance_label}</Label>
                <div className="my-px">
                  {TRAVEL_DISTANCE_OPTIONS.map((option) =>
                    <Checkbox
                      outlined
                      key={option.value}
                      value={!!values['travelDistancePreferences']?.includes(option.value)}
                      onChange={(v) => onCheckboxClick('travelDistancePreferences', option.value, v)}
                    >
                      <div className="ml-1 my-1 text-midnight font-lato">{option.label}</div>
                    </Checkbox>
                  )}
                </div>
                <FormErrorMessage error={errors.travelDistancePreferences} />
              </div>
            )}
            {Object.values(touched).some(Boolean) && (
              <SettingsActionBar
                cancelDisabled={isUpdating}
                saveDisabled={isUpdating}
                onCancel={async () => {
                  for (let [key, value] of Object.entries(initialValues)) {
                    await setFieldValue(key, value);
                  }
                  await setTouched({});
                }}
                onSave={async () => {
                  setIsUpdating(true);
                  const errors = await validateForm();
                  if (!Object.keys(errors).length) {
                    await updateTalent(values);
                  }
                  setIsUpdating(false);
                }}
              />
            )}
          </Form>
        )
      }}
    </Formik>);
}

export default OpportunityPreferences;
