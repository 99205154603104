import React from "react";

const FileUploadIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.3381 17.2632C24.9726 17.2632 24.6762 17.5595 24.6762 17.9251V20.4542C24.6762 21.914 23.4886 23.1017 22.0288 23.1017H3.97125C2.51144 23.1017 1.32377 21.914 1.32377 20.4542V17.9251C1.32377 17.5595 1.02746 17.2632 0.661883 17.2632C0.296309 17.2632 0 17.5595 0 17.9251V20.4542C0 22.6439 1.78151 24.4254 3.97125 24.4254H22.0288C24.2185 24.4254 26 22.6439 26 20.4542V17.9251C26 17.5595 25.7037 17.2632 25.3381 17.2632Z" fill="#54698D"/>
      <path d="M21.3196 19.8708H4.68032C4.31484 19.8708 4.01843 20.1672 4.01843 20.5327C4.01843 20.8983 4.31474 21.1946 4.68032 21.1946H21.3196C21.6851 21.1946 21.9814 20.8983 21.9814 20.5327C21.9814 20.1672 21.6851 19.8708 21.3196 19.8708Z" fill="#54698D"/>
      <path d="M19.6464 6.94853L14.1024 1.99579C13.4739 1.4344 12.5262 1.4343 11.8976 1.99579L6.35365 6.94848C6.081 7.19198 6.05744 7.61041 6.30099 7.88306C6.54448 8.1556 6.96287 8.17937 7.23562 7.93572L12.3382 3.37739V16.2052C12.3382 16.5708 12.6346 16.8671 13.0001 16.8671C13.3657 16.8671 13.662 16.5708 13.662 16.2052V3.37739L18.7646 7.93572C18.8908 8.0485 19.0483 8.10406 19.2053 8.10406C19.3872 8.10406 19.5683 8.02946 19.6992 7.88311C19.9426 7.61046 19.919 7.19213 19.6464 6.94853Z" fill="#54698D"/>
    </svg>
  )
};

export default FileUploadIcon;
