import React, {useState} from "react";
import * as yup from "yup";
import {FormErrorMessage, Label, Modal} from "../components";
import $ from "../../../strings/talent.json";
import {Form, Formik} from "formik";
import {Input, Textarea} from "../../../components/Form";
import {FileInput} from "../components/FileInput";


export const AddTalentProjectFileModal = ({ initialValues, closeModal, saveFile, deleteFile }) => {
  const [isUpdating, setIsUpdating] = useState(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        yup.object().shape({
          // required only if initialValues.filename is empty because that means editing
          // but when editing file will be empty because we're not fetching file content each time
          file: initialValues.filename ? yup.string() : yup.string().required('Required'),
          title: yup.string().required('Required'),
        })}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={() => {}}
      enableReinitialize={true}
    >
      {({
        setFieldValue,
        values,
        touched,
        setTouched,
        setFieldTouched,
        errors,
        setFieldError,
        validateForm
      }) => {
        const onChange = async (e) => {
          let {name, value} = e.target;
          await setFieldValue(name, value);
          await setFieldTouched(name, value !== initialValues[name]);
          if (errors[name]) {
            setFieldError(name, null);
          }
        };

        const save = async () => {
          setIsUpdating(true);
          try {
            const validationErrors = await validateForm();
            if (Object.keys(validationErrors).length > 0) {
              return;
            }
            const file = {
              file: values.file,
              title: values.title,
              filename: values.filename,
              file_type: values.file_type,
              description: values.description,
            };
            if (initialValues.id) {
              file.id = initialValues.id;
            }
            saveFile(file);
            setIsUpdating(false);
            closeModal();
          } finally {
            setIsUpdating(false);
          }
        };

        return (
          <Modal
            title={$.add_file_title}
            onClose={() => {
              setIsUpdating(false);
              closeModal();
            }}
            onClickSave={save}
            buttonsDisabled={isUpdating}
            onClickDelete={() => {
              deleteFile({ ...values, id: initialValues.id });
              closeModal();
            }}
            showDelete={!!initialValues.id}
          >
            <Form className="form">
              <div className="flex flex-col">
                <div>
                  <FileInput
                    name="file"
                    multiple={false}
                    maxSize={50}
                    filename={values.filename}
                    disabled={!values.file && values.filename}
                    disabledMsg="Editing file is not supported"
                    onChange={file => {
                      const reader = new FileReader();
                      reader.onload = () => {
                        const idx = file.name.lastIndexOf(".");
                        let file_type = "";
                        if (idx > -1) {
                          file_type = file.name.slice(idx + 1).toUpperCase();
                        }
                        const fileData = reader.result.replace(/data:.*base64,/g, "");
                        onChange({ target: { name: "file", value: fileData } }).then();
                        setFieldValue("file_type", file_type).then();
                        setFieldValue("filename", file.name).then();
                      }
                      reader.readAsDataURL(file);
                    }}
                  />
                  <FormErrorMessage error={errors.file}/>
                </div>
                <div>
                  <Label>{$.add_file_title_label}</Label>
                  <Input
                    name="title"
                    value={values.title}
                    error={errors.title}
                    onChange={onChange}
                    placeholder={$.add_url_title_placeholder}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        save().then();
                      }
                    }}
                  />
                  <FormErrorMessage error={errors.title}/>
                </div>
                <div>
                  <Label>{$.add_url_description_label}</Label>
                  <Textarea
                    name="description"
                    value={values.description}
                    error={errors.description}
                    onChange={onChange}
                    placeholder={$.add_url_description_placeholder}
                    className="resize-none h-25"
                    unbolded
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        save().then();
                      }
                    }}
                  />
                </div>
              </div>
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
};
