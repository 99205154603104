export const SHELL_COMPANY_IDS = [
  'cebd973d-deb1-480a-a7ae-f05abe6fbc61',
  '1f3aa606-c307-4559-acb8-beddad86d893',
];

export const getProjectCompletion = project => {
  let projectPercentage = 0;
  if (project.name) {
    projectPercentage += 4;
  }
  if (project.experience_type) {
    projectPercentage += 2;
  }
  if (project.company) {
    projectPercentage += 10;
  }
  if (project.company_employee_count) {
    projectPercentage += 4;
  }
  if (project.start_date) {
    projectPercentage += 3;
  }
  const skillsUsed = project.skills_and_tools?.filter(
    el => el.type?.toLowerCase() === 'skill'
  );
  const toolsUsed = project.skills_and_tools?.filter(
    el => el.type?.toLowerCase() === 'tool'
  );
  if (skillsUsed.length) {
    projectPercentage += 3;
  }
  if (toolsUsed.length) {
    projectPercentage += 3;
  }
  if (project.work_sample) {
    projectPercentage += 20;
  }
  if (project.results) { // contributions and results
    projectPercentage += 51;
  }
  return projectPercentage;
};

export const getTalentProfileCompletion = (talentData) => {
  let percentage = 0;
  if (talentData.user?.profile_photo) {
    percentage += 10;
  }
  if (talentData.about_me) {
    percentage += 10;
  }
  const getQualificationsByType = type => talentData.qualifications.filter(
    el => el.qualification?.type?.toLowerCase() === type.toLowerCase()
  );
  const skills = getQualificationsByType('skill');
  const industries = getQualificationsByType('industry');
  const tools = getQualificationsByType('tool');
  if (skills.length >= 5) {
    percentage += 10;
  }
  if (industries.length >= 1) {
    percentage += 10;
  }
  if (tools.length >= 5) {
    percentage += 10;
  }
  const projectsAtLeast50 = talentData.talent_created_projects.filter(
    p => getProjectCompletion(p) >= 50
  );
  percentage += (15 * Math.min(projectsAtLeast50.length, 3));
  return percentage;
};
