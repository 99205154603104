import React from 'react';

export const LinkPreview = ({ name, ...props }) => {
  return (
    <div
      {...props}
      className={`flex mt-1 cursor-default select-none ${props.className}`}
    >
      <div className="h-17.5 w-30 flex bg-blue-200 rounded-lg ">
        <div className="m-auto text-sm">tbd link preview</div>
      </div>
      <div className="h-17.5 mx-2.5 flex flex-col font-bold">
        <div className="my-auto">{name}</div>
      </div>
    </div>
  )};
