import React from "react";


const Button = ({
  children,
  secondary,
  danger,
  disabled,
  loading,
  cancel,
  noMinW,
  large,
  className = "",
  ...props
}) => (
  <button
    type={props.type || "button"}
    {...props}
    disabled={disabled || loading}
    className={
      "group focus:outline-none h-10 flex " +
      (noMinW ? "" : " min-w-36 ") +
      "rounded-lg gap-1 " +
      className
    }
  >
    <span
      className={
        "h-9.5 text-sm group-focus:ring focus:outline-none font-bold rounded-lg inline-flex items-center justify-center whitespace-nowrap " +
        (large ? "h-12 text-lg " : "") +
        (noMinW ? "px-2.5 " : "min-w-36 px-11.5 ") +
        (
          (disabled || loading) ? "text-white bg-neutral-light cursor-not-allowed "
          : secondary ? "bg-white text-electric-indigo border border-electric-indigo hover:text-electric-indigo-lighter hover:border-electric-indigo-lighter "
          : cancel ? "bg-alice-blue text-electric-indigo hover:bg-alice-blue-lighter "
          : "bg-electric-indigo text-white hover:bg-electric-indigo-lighter "
        ) + className
      }
      tabIndex="-1"
    >
      {children}
    </span>
  </button>
);

export default Button;
