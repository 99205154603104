import React, { useMemo } from "react";
import $ from "strings/talent";
import { EditBtn } from "components/Buttons";

const AdditionalLinks = ({ talentData, setTalentData, isPublicProfile }) => {
  return (
    <div className="bg-white rounded-lg p-4 gap-y-3.5 flex flex-col">
      <div className="flex items-center">
        <div className="text-lg font-bold">Additional Links</div>
        {!isPublicProfile && <div className="ml-auto mr-0">
          <EditBtn />
        </div>}
      </div>
      <div className="text-sm flex gap-x-3">
        {/* todo add links */}
      </div>
    </div>
  )
};

export default AdditionalLinks;
