import React from "react";

const Input = React.forwardRef(({ children, type = "text", error, fontSize, fontWeight, ...props }, ref) => (
  <div
    className={
      (props.className ? `${props.className} ` : "") +
      `w-full flex rounded h-10 px-1 items-center text-midnight bg-white  ` +
      (fontSize || "") + ' ' +
      (fontWeight || "") + ' ' +
      (error
        ? "border-red border-2"
        : props.disabled
        ? "border border-geyser bg-link-water"
        : "border border-geyser focus:border-link focus:border-2")
    }>
    {props.prefix &&
      <span
        className="bg-lightest-grey text-kasmir rounded my-1 h-8 inline-flex items-center px-1.5"
      >{props.prefix}</span>
    }
    <input
      ref={ref}
      {...props}
      className="placeholder-text-kasmir placeholder:font-normal focus:outline-none appearance-none ml-2 flex-auto"
      onWheel={(e) => e.target.blur()}
      type={type}
    />
  </div>
));

export default Input;
