import $ from "../../../strings/talent.json";
import {Checkbox} from "../../../components/Form";
import {FormErrorMessage, Modal} from "../components";
import React, {useEffect, useState} from "react";
import {
  postTalentTalentCreatedCompanyFromURL,
  postTalentTalentCreatedProject
} from "../../../utils/api";
import AlertError from "../../../components/AlertError";
import {useAlert} from "react-alert";

const ImportRSUProjectForm = ({ setIsOpen, RSUProjects }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedRSUProjects, setSelectedRSUProjects] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [formError, setFormError] = useState('');

  const alert = useAlert();

  useEffect(() => {
    setSelectedRSUProjects(prevState => {
    if (!selectAll) {
      return {};
    }
      const newState = {};
      for (const key in RSUProjects) {
        newState[key] = RSUProjects[key];
      }
      return newState;
    });
  }, [selectAll]);

  useEffect(() => {
    if (Object.values(selectedRSUProjects).length > 0) {
      setFormError('');
    }
    if (Object.values(selectedRSUProjects).length !== Object.values(RSUProjects).length) {
      setSelectAll(false);
    }
  }, [selectedRSUProjects]);

  const importRSUProjects = () => {
    setIsUpdating(true);
    if (!Object.values(RSUProjects).length) {
      setFormError("There are no RSU projects available to import.");
      setIsUpdating(false);
      return;
    }
    if (!Object.values(selectedRSUProjects).length) {
      setFormError("Please select at least one project to import.");
      setIsUpdating(false);
      return;
    }

    Object.values(selectedRSUProjects).forEach(project => {
      const qualifications = project.qualifications
        .filter(qualification =>
          qualification.type.toLowerCase() === "skill" || qualification.type.toLowerCase() === "tool"
        )
        .map(qualification => qualification.id);
      let website = project.company_website;

      const projectData = {
        name: project.name,
        company_id: null,
        skills_and_tools_ids: qualifications,
        project_id: project.id,
      };
      if (website) {
        if (!website.startsWith('http')) {
          website = `https://${website}`;
        }
        projectData.company_domain = website;
      }
      if (project.start_date) {
        projectData.start_date = project.start_date.split('T')[0];
      }
      if (project.end_date) {
        projectData.end_date = project.end_date.split('T')[0];
      }

      // postTalentTalentCreatedProject performs a get_or_create
      const post = (data) => postTalentTalentCreatedProject(data)
        .then(() => {
          setIsUpdating(false);
          setIsOpen(false);
        })
        .catch(() => {
          alert.error(<AlertError message={`Unable to import project: ${project.name}. Please try again in the moment or contact support.`} />);
        });

      postTalentTalentCreatedCompanyFromURL({ website, name: project.company_name })
        .then((company) => {
          post({ ...projectData, company_id: company.id }).then();
        })
        .catch(() => {
          post({ ...projectData }).then();
        });
    });

    setIsUpdating(false);
  };

  return (
    <Modal
      title={$.import_rsu_project_title}
      onClose={() => setIsOpen(false)}
      onClickCancel={() => setIsOpen(false)}
      onClickSave={importRSUProjects}
      buttonsDisabled={isUpdating}
      saveBtnText={$.import_btn}
    >
      <div className="mb-2.5">
        <h1 className="text-base font-bold text-midnight mb-1.25">
          {$.import_rsu_project_subtitle}
        </h1>
        <h2 className="text-xs font-medium text-kasmir">
          {$.import_rsu_project_info}
        </h2>
      </div>
      {Object.values(RSUProjects).length > 0 ? (
        <div className="flex flex-col">
          <Checkbox
            outlined
            value={selectAll}
            onChange={() => setSelectAll(!selectAll)}
          >
            <div className="ml-1 my-1 font-sm text-electric-indigo font-lato font-bold">
              {$.select_all_rsu_projects_option}
            </div>
          </Checkbox>
          {Object.values(RSUProjects).map(project => (
            <Checkbox
              outlined
              key={project.id}
              value={!!selectedRSUProjects[project.id]}
              onChange={v => {
                if (v) {
                  setSelectedRSUProjects(prevState => ({
                    ...prevState,
                    [project.id]: project
                  }));
                } else {
                  setSelectedRSUProjects(prevState => {
                    const newState = {...prevState};
                    delete newState[project.id];
                    return newState;
                  });
                }
              }}
            >
              <div className="ml-1 my-1 font-semibold text-midnight font-lato">
                {project.name}
              </div>
            </Checkbox>
          ))}
        </div>
      ) : (
        <div className="mt-2 font-bold text-midnight">
          No RSU project available to import.
        </div>
      )}
      <FormErrorMessage error={formError} />
    </Modal>
  );
};

export default ImportRSUProjectForm;
