import React, { useState } from "react";
import { StarIcon } from "../onboard/icons/index";
/**
 * Renders a rating input with stars. Used to rate qualifications.
 * @param {int} value - The value of the input.
 * @param {callback} onChange - Callback to handle value changes.
 * @param {boolean} disabled - Whether the input is disabled.
 * @param {string} starGap - Gap between the star icons
 * @param {int} starSize - Size of the star icons indicating the rating.
 */
const RatingInput = ({ value, onChange, disabled, starGap='gap-2', starSize=22 }) => {
  const [hover, setHover] = useState();

  const count = hover || value;

  return (
    <span className="text-base font-serif mx-1 flex items-center">
      <span
        className={`flex items-center cursor-pointer ${starGap}`}
        onMouseLeave={(e) => {if (!disabled) setHover()}}>
        {[...Array(5)].map((e, i) => (
          <div
            key={i}
            onMouseEnter={(e) => {if (!disabled) setHover(i + 1)}}
            onClick={() => {if (!disabled) onChange(value === i + 1 ? 0 : i + 1)}}
          >
            <StarIcon
              isSelected={count >= i + 1}
              className="mx-px"
              size={starSize}
              key={i}
            />
          </div>
        ))}
      </span>
    </span>
  );
};

export default RatingInput;
