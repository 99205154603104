import React, {useEffect, useState} from "react";
import $ from "strings/talent";
import { SPECIALTY_OPTIONS } from "../onboard/constants";
import { ExperienceIcon, LocationIcon, SpecialtiesIcon } from "pages/talent/onboard/icons";
import { EditBtn } from "components/Buttons";
import userImg from "assets/user.svg";
import ProfilePhotoForm from "./ProfilePhotoForm";
import {Button} from "../components";
import WorkWithForm from "./WorkWithForm";
import {getUserId} from "../../../utils/localStorageService";

const Header = ({ talentData, setTalentData, isPublicProfile, setEditProfilePhoto }) => {
  const [modal, setModal] = useState('');
  const [loggedInUserId, setLoggedInUserId] = useState(getUserId());
  const { user, specialties, years_of_experience, currently_available } = talentData;
  const uci = user.user_contact_info;
  let location = 'Location unknown';
  if (uci) {
    const {city, state_code, state, country} = uci;
    if (city && state_code) {
      location = `${city}, ${state_code}`;
    } else if (city && country) {
      location = `${city}, ${country}`;
    } else if (city) {
      location = city;
    } else if (country) {
      location = country;
    } else if (state) {
      location = state;
    } else if (state_code) {
      location = state_code;
    }
  }

  useEffect(() => {
    if (setEditProfilePhoto) {
      setEditProfilePhoto(() => () => setModal('photo'));
    }
  }, [setEditProfilePhoto]);

  return (
    <div className="bg-white text-midnight rounded-lg">
      <div className="flex-1 h-24 bg-alice-blue rounded-t-lg relative" />
      <div className="absolute top-36 rounded-xl h-45 w-45 ml-4">
        <img className="relative rounded-lg" src={user.profile_photo || userImg} />
        {!isPublicProfile && <EditBtn className="absolute -top-1 -right-1 rounded-xl z-10" onClick={() => setModal('photo')} />}
      </div>
      <div className="flex-1 bg-white p-2 rounded-b-lg">
        <div className="flex flex-col">
          <div className="ml-50">
            <div className="flex flex-row gap-2.5">
              <div className="flex flex-col gap-y-1.5">
                <div className="flex items-center mb-1">
                  <h1 className="text-1.5xl font-bold">{user.first_name} {user.last_name}</h1>
                  &nbsp;
                  <div className="text-normal pt-1">({user.pronouns})</div>
                  <div className="ml-2.5">
                    {!currently_available &&
                      <div className="bg-muted-green text-medium-green py-1 px-2 rounded-lg text-sm">
                        {$.currently_available}
                      </div>
                    }
                  </div>
                </div>
                <div className="flex gap-2 py-1.5">
                  <LocationIcon />
                  <div className="flex gap-x-1.5 text-sm">
                    <div>{$.location_header_label}</div>
                    <div className="text-kasmir">{location}</div>
                  </div>
                </div>
                <div className="flex gap-2 py-1.5 text-sm">
                  <ExperienceIcon/>
                  <div className="flex gap-x-1.5">
                    <div>{$.experience_header_label}</div>
                    <div className="text-kasmir">{years_of_experience} {years_of_experience === 1 ? 'year' : 'years'}</div>
                  </div>
                </div>
                <div className="flex gap-1.5 items-center text-sm">
                  <div className="flex flex-row mb-auto gap-2 py-1.5">
                    <SpecialtiesIcon />
                    <div>{$.specialties_header_label}</div>
                  </div>
                  <div className="flex flex-wrap gap-1.5">
                    {specialties && specialties.split("|").map(s =>
                      <div key={s} className="bg-lightest-grey py-1.5 px-2.5 rounded-lg text-sm text-midnight whitespace-nowrap">
                        {SPECIALTY_OPTIONS.find(o => o.value === s)?.label}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {isPublicProfile ? <div className="ml-auto mt-auto">
                <Button
                  noMinW={true}
                  onClick={() => {
                    if (loggedInUserId === user.id) {
                      // this is when talent is viewing their own profile in public mode
                      return;
                    }
                    setModal('work-with');
                  }}
                >{$.work_with_btn} {user.first_name}</Button>
              </div> : <div className="ml-auto mt-auto">
                <Button
                  noMinW={true}
                  onClick={() => {
                    // open {current_url}/?public=true in a new tab
                    window.open(`${window.location.href}?public=true`, '_blank');
                  }}
                >{$.see_public_view_btn}</Button>
              </div>}
            </div>
          </div>
        </div>
      </div>
      {modal === "photo" &&
        <ProfilePhotoForm
          onClose={() => setModal('')}
          talentData={talentData}
          setTalentData={setTalentData}
        />
      }
      {modal === "work-with" &&
        <WorkWithForm
          onClose={() => setModal('')}
          talentData={talentData}
          setTalentData={setTalentData}
        />
      }
    </div>
  )
};

export default Header;
