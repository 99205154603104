import React, { useEffect, useCallback } from "react";
import $ from "strings/talent";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import Section from "components/Section";
import useConfirmClose from "hooks/useConfirmClose";
import { TransparentButton } from "components/Buttons";
import {Button} from "./index";

/**
 * Renders a modal close button.
 * @param {callback} onClick - Callback to trigger on click.
 */
const CloseBtn = ({ onClick }) => (
  <button onClick={onClick} className="group focus:outline-none h-full flex">
    <span
      className="group-focus:ring focus:outline-none rounded text-lg w-8 h-8 text-cove inline-flex items-center justify-center hover:bg-link-water active:bg-geyser"
      tabIndex="-1"
    >
      <UilTimes size="24" />
    </span>
  </button>
);

/**
 * Renders a modal. It includes some features like confirmation prompt on close and a close button. It is used in all forms.
 * @param {component} children - Children to render inside, like a form with all its inputs.
 * @param {callback} onClose - Callback to trigger on close button click.
 * @param {string} id - Identifier to use with the Section component.
 * @param {boolean} fullHeight - Flag to indicate if the modal should take full height.
 * @param {boolean} isDirty - Flag to enable the confirmation feature.
 */
const Modal = ({
  children,
  onClose,
  id,
  isDirty,
  title,
  titleChildren,
  onClickSave,
  onClickCancel,
  buttonsDisabled,
  saveBtnText,
  showDelete,
  cancelBtnText,
  deleteBtnText,
  onClickDelete,
  className=""
}) => {
  const { canClose, confirmMsg } = useConfirmClose(isDirty);

  const onEscape = useCallback(
    (event) => {
      if (event.keyCode === 27 && canClose()) {
        onClose();
      }
    },
    [canClose, onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", onEscape);

    return () => {
      document.removeEventListener("keydown", onEscape);
    };
  }, [onEscape]);

  return (
    <div className="py-12 px-10 z-50 fixed top-0 left-0 w-screen h-screen items-center justify-center flex text-midnight">
      <div
        onClick={() => {
          if (canClose()) {
            onClose();
          }
        }}
        className="absolute top-0 left-0 w-full h-full z-40"
        style={{ backgroundColor: "rgba(31, 34, 39, 0.5)" }}
      />
      <Section
        id={id}
        className={"flex z-50 mx-auto max-h-full w-full lg:w-3/5 xl:w-3/5 2xl:w-1/2"}
      >
        <div className={`rounded-xl flex flex-col bg-white max-h-full h-auto w-full mt-0 mb-0 ${className}`}>
          <div className="flex p-5 border-b-2">
            <div className="text-xl font-bold">{title}</div>
            <div className="flex items-center justify-end ml-auto mr-0 gap-x-4">
              {!!titleChildren && (<div>{titleChildren}</div>)}
              <CloseBtn
                onClick={() => {
                  if (canClose()) {
                    onClose();
                  }
                }}
              />
            </div>
          </div>
          <div className="px-5 py-3.5 overflow-scroll">
            {children}
          </div>
          <div className="flex p-5 border-t-2">
            {showDelete &&
              <TransparentButton disabled={buttonsDisabled} className="text-electric-indigo underline text-sm" onClick={onClickDelete}>
                <span>{deleteBtnText || 'Delete'}</span>
              </TransparentButton>
            }
            <div className="flex gap-2.5 ml-auto">
              <Button
                disabled={buttonsDisabled}
                onClick={onClickCancel || onClose}
                cancel
              >
                <span>{cancelBtnText || $.cancel_btn}</span>
              </Button>
              <Button
                disabled={buttonsDisabled}
                onClick={onClickSave}
              >
                <span>{saveBtnText || $.save_btn}</span>
              </Button>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default Modal;
