import React from "react";
import clsx from "clsx";

export const QualificationsDisplay = ({qualifications, className='', large=false}) => {
  return (
    <div className={clsx("text-sm flex flex-wrap", large ? "gap-2" : "gap-1.5", className)}>
      {qualifications.map((q, i) =>
        <div
          className={clsx(
            "bg-lightest-grey rounded-md text-sm font-semibold text-midnight",
            large ? "px-2.5 py-2" : "px-2 py-1.5"
          )}
          key={i}
        >
          {q?.name}
        </div>
      )}
    </div>
  )
}
