import React, {useEffect, useState} from "react";
import {admin_login as $} from "strings";
import logoSm from "assets/logo_sm.svg";
import {useAlert} from "react-alert";
import AlertError from "components/AlertError";
import {Input} from "../../components/Form";
import {registerPassword} from "../../utils/api";
import {useHistory} from "react-router-dom";
import {Form, Formik} from "formik";
import * as yup from "yup";
import {yupPasswordSchema} from "../../utils/validation";
import {Button, FormErrorMessage} from "./components";
import {saveLoginToken} from "../../utils/localStorageService";

const Register = ({ setLogged }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const alert = useAlert();
  const history = useHistory();
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    document.title = "Create Your Password";
    const params = new URLSearchParams(window.location.search);
    setUserId(params.get('userId'));
    setEmail(params.get('email'));
  }, []);

  const initialValues = {
    password: '',
    confirmPassword: ''
  };

  const validationSchema = yup.object().shape({
    password: yupPasswordSchema,
    confirmPassword: yup.string().required("This field is required").equals([yup.ref('password')], "Passwords must match")
  });

  return (
    <div className="font-lato bg-link-water w-screen h-screen flex items-center justify-center px-24 py-12">
      <div className="rounded-2xl bg-white relative w-full h-full">
        <div className="absolute top-0 left-0 h-10 w-10 ml-8 mt-8">
          <img
            className="max-w-full max-h-full"
            src={logoSm}
            alt="Right Side Up"
          />
        </div>
        <div className="w-full h-full flex">
          <div className="w-1/2">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={() => {}}
              enableReinitialize={true}
            >
              {({
                setFieldValue,
                values,
                touched,
                setTouched,
                setFieldTouched,
                errors,
                setFieldError,
                validateForm
              }) => {
                const onChange = async e => {
                  const { name, value } = e.target;
                  await setFieldValue(name, value);
                  await setFieldTouched(name, value !== initialValues[name]);
                  if (errors[name]) {
                    setFieldError(name, null)
                  }
                };

                const handleRegisterPassword = async () => {
                  if (!email || !userId) {
                    alert.error(<AlertError error={{message: 'Email and userId are required'}} message={$.auth_error} />);
                    return;
                  }
                  try {
                    const errors = await validateForm();
                    if (Object.keys(errors).length) {
                      return;
                    }
                    const response = await registerPassword({ userId, password: values.password });
                    const talent = response.talent;
                    if (!talent) {
                      alert.error(<AlertError message="Talent not found." />);
                      return;
                    }
                    saveLoginToken(response);
                    setLogged(true);
                    history.push(`/talent/${response.talent.url_suffix}`);
                  } catch (e) {
                    console.error(e);
                    const message = e.response?.data?.error || e.message;
                    alert.error(<AlertError message={message} />);
                  }
                };

                return (
                  <Form className="form h-full">
                    <div className="flex flex-col h-full items-center justify-center px-10">
                      <h1 className="font-semibold text-2xl mb-4">
                        Create a password
                      </h1>
                      <label className="text-sm text-left w-full mb-1">
                        Enter Password
                      </label>
                      <div className="w-full mb-2">
                        <Input
                          type="password"
                          name="password"
                          fontSize="text-sm"
                          value={values.password}
                          onChange={onChange}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              handleRegisterPassword().then();
                            }
                          }}
                        />
                        <FormErrorMessage error={errors.password} />
                      </div>
                      <label className="text-sm text-left w-full mb-1">
                        Confirm Password
                      </label>
                      <div className="w-full mb-2">
                        <Input
                          type="password"
                          name="confirmPassword"
                          fontSize="text-sm"
                          value={values.confirmPassword}
                          onChange={onChange}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              handleRegisterPassword().then();
                            }
                          }}
                        />
                        <FormErrorMessage error={errors.confirmPassword} />
                      </div>
                      <Button
                        className="w-full"
                        large
                        disabled={isUpdating}
                        onClick={async () => {
                          setIsUpdating(true);
                          await handleRegisterPassword();
                          setIsUpdating(false);
                        }}
                      >
                        Continue
                      </Button>
                    </div>
                  </Form>
                )}}
            </Formik>
          </div>
          <div
            className="w-1/2 h-full bg-lightest-grey rounded-2xl pt-10 text-center flex flex-col justify-between"
          >
            <div className="px-8 h-1/4">
              <h1 className="text-3xl">
                Be your own boss, boss
              </h1>
              <p className="text-sm mt-2">
                Deliver excellent work that thrills clients by taking on
                projects
                that light you up. Whether you want to moonlight or freelance
                full
                time, you dictate who you work with, how many hours you work,
                and how much you get.
              </p>
            </div>
            <div className="flex-auto w-full flex flex-row text-white text-xl">
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-1/2 bg-link"></div>
              </div>
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-20 bg-link-dark pt-6">RIGHT</div>
                <div className="h-1/2 bg-link-dark"></div>
              </div>
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-32 bg-indigo pt-6">SIDE</div>
                <div className="h-1/2 bg-indigo"></div>
              </div>
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-44 bg-link-dark pt-6">UP</div>
                <div className="h-1/2 bg-link-dark"></div>
              </div>
              <div className="flex flex-col w-1/5">
                <div className="flex-auto"></div>
                <div className="h-52 bg-indigo"></div>
                <div className="h-1/2 bg-indigo"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
