import React from "react";
import $ from "strings/talent";
import { TransparentButton } from "components/Buttons";
import UilArrowRight from "@iconscout/react-unicons/icons/uil-arrow-right";
import {Button} from "./index";

const validatedFieldMappings = {
  0: ['firstName', 'lastName', 'phone', 'schedulingLink', 'communicationPreferences'],
  1: ['employmentStatus', 'businessHoursAvailable', 'openToFulltimeFreelance'],
  2: ['fulltimeInterest', 'onsiteFrequency', 'travelDistancePreferences'],
  3: ['hoursAvailable', 'hoursExpirationDate', 'workingTimezones'],
  4: ['minimumRate', 'yearsOfExperience'],
  5: ['specialties'],
  6: ['qualificationsRatings'],
  7: ['toolsRatings'],
  8: ['industries'],
  9: ['other']
}

const ActionBar = ({
  step,
  onClickPrev,
  onClickNext,
  onClickSubmit,
  validateForm,
  setTouched,
  formSubmitting,
  stepsWithErrors,
  setStepsWithErrors
}) => {
  const handleClickNext = () => {
    const validatedFields = validatedFieldMappings[step];
    validateForm().then((errors) => {
      let errorFields = Object.keys(errors);
      if (validatedFields.some(field => errorFields.indexOf(field) > -1)) {
        setStepsWithErrors([...stepsWithErrors, step]);
        setTouched({});
        return;
      } else {
        setStepsWithErrors([...stepsWithErrors.filter(s => s !== step)]);
        onClickNext();
      }
    })
  }

  return (
    <div className="flex w-full">
      <div className="ml-0 mr-auto">
        {step !== 0 &&
          <TransparentButton className="text-sm sm:text-base" onClick={onClickPrev}>{$.prev_btn}</TransparentButton>
        }
      </div>
      <div className="mr-0 ml-auto">
        {step === 9 ?
          <Button disabled={formSubmitting} className="animatedBtn flex" onClick={onClickSubmit}>
            <span>{$.finish_btn}</span>
            <UilArrowRight size="16"/>
          </Button> :
          <Button className="animatedBtn flex" onClick={handleClickNext}>
            <span>{$.next_btn}</span>
            <UilArrowRight className="icon" size="16"/>
          </Button>
        }
      </div>
    </div>
  )
};

export default ActionBar;
