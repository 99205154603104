import React, { useMemo } from "react";
import { EditBtn } from "components/Buttons";
import Pill from "../components/Pill";
import {
  QualificationsDisplay
} from "../../../components/QualificationsDisplay";

const Skills = ({ talentData, isPublicProfile }) => {
  const { qualifications } = talentData;

  const skills = useMemo(() => {
    if (!qualifications) return;
    return qualifications.filter(
      q => q.qualification?.type.toLowerCase() === 'skill' &&
        (!isPublicProfile || !!q.display_on_talent_profile)
    ).map(s => s.qualification);
  }, [qualifications, isPublicProfile]);

  return (
    <div className="bg-white rounded-lg p-4 flex flex-col gap-4">
      <div className="flex gap-2 items-center">
        <div className="text-lg font-bold text-midnight">Skills</div>
        <Pill value={skills?.length || 0} />
        {!isPublicProfile && <div className="ml-auto">
          <EditBtn className="w-full" />
        </div>}
      </div>
      {skills && <QualificationsDisplay qualifications={skills} large />}
    </div>
  )
};

export default Skills;
