import React from "react";

/**
 * Renders sublabel to be used with a form field.
 * @param {component} children - The children to render, in most cases a clear button.
 * @param {string} className - Classes to append to the label default classes.
 */
const Sublabel = ({ children, className = "" }) => (
  <div
    className={
      "text-kasmir font-normal text-xs mb-1 flex items-center " + className
    }
  >
    {children}
  </div>
);

export default Sublabel;
