import React from "react";
import $ from "strings/talent";
import {
  Label,
  Sublabel,
  Checkbox,
  Radio
} from "components/Form";
import { Error } from "../../components/index";

const FULLTIME_INTEREST_OPTIONS = [
  {label: $.yes_option, value: true},
  {label: $.no_option, value: false}
]

const ONSITE_FREQUENCY_OPTIONS = [
  {label: $.regular_days_option, value: 'regular'},
  {label: $.occasional_meetings_option, value: 'occasional'},
  {label: $.special_events_option, value: 'special'},
  {label: $.not_interested_travel_option, value: 'not_interested' }
]

const TRAVEL_DISTANCE_OPTIONS = [
  {label: $.local_travel_option, value: 'local'},
  {label: $.domestic_travel_option, value: 'domestic'},
  {label: $.international_travel_option, value: 'international'}
]

const OpportunityPreferences = ({
  setFieldValue,
  values,
  touched,
  errors,
  setFieldError
}) => {
  const onCheckboxClick = (fieldName, option, value) => {
    // if user selects Not Interested, override other selections
    if (value === true && option === 'not_interested') {
      setFieldValue(fieldName, option);
      setFieldValue('travelDistancePreferences', null);
      return;
    }
    const currentValues = values[fieldName];
    let currentValuesArr = currentValues?.length ? currentValues.split('|') : [];
    if (value === true && currentValuesArr.indexOf(option) === -1) {
      currentValuesArr.push(option);
      if (errors[fieldName]) {
        setFieldError(fieldName, null);
      }
      // if user selects an option other than Not Interested, remove Not Interested
      if (currentValuesArr.indexOf('not_interested') > -1) {
        currentValuesArr.splice(currentValuesArr.indexOf('not_interested'), 1)
      }
    } else if (value === false && currentValuesArr.indexOf(option) > -1) {
      currentValuesArr.splice(currentValuesArr.indexOf(option), 1)
    }
    const newValues = currentValuesArr.sort().join('|')
    setFieldValue(fieldName, newValues);
  }

  const onRadioClick = (name, value) => {
    setFieldValue(name, value);
    if (errors[name]) {
      setFieldError(name, null)
    }
  }

  return (
    <div className="px-8 py-4 grow">
      <div className="py-1 mb-auto">
        <div className="text-darkest-navy text-2xl sm:text-3xl font-bold py-2">{$.opportunity_preferences_title}</div>
        <div className="text-midnight text-base sm:text-lg font-normal">{$.opportunity_preferences_subtitle}</div>
      </div>
      <div className="overflow-visible scrollableSurveyContent flex flex-col gap-2 py-4">
        <div className="my-px">
          <Label className="font-normal text-darkest-navy text-sm sm:text-base">{$.fulltime_interest_label}</Label>
          {FULLTIME_INTEREST_OPTIONS.map((option) => 
            <Radio
              outlined
              key={option.value}
              name='fulltimeInterest'
              value={option.value}
              checked={values['fulltimeInterest'] === option.value}
              onChange={() => onRadioClick('fulltimeInterest', option.value)}
            >
             <div className="ml-1 my-1 font-sm text-darkest-navy font-lato">{option.label}</div>
           </Radio>
          )}
          {(!touched.fulltimeInterest && errors.fulltimeInterest) ? <Error msg={errors.fulltimeInterest} /> : <div style={{height: '22px'}} />}
        </div>
        <div className="my-px">
          <Label className="font-normal text-darkest-navy text-sm sm:text-base">{$.onsite_frequency_label}</Label>
          <Sublabel>{$.onsite_frequency_sublabel}</Sublabel>
          {ONSITE_FREQUENCY_OPTIONS.map((option) => 
            <Checkbox
              outlined
              key={option.value}
              value={values['onsiteFrequency']?.includes(option.value) ? true : false}
              onChange={(v) => onCheckboxClick('onsiteFrequency', option.value, v)}
              disabled={
                values['onsiteFrequency']?.includes('not_interested' && option.value !== 'not_interested')
              }
            >
              <div className="ml-1 my-1 font-sm text-darkest-navy font-lato">{option.label}</div>
            </Checkbox>
          )}
          {(!touched.onsiteFrequency && errors.onsiteFrequency) ? <Error msg={errors.onsiteFrequency} /> : <div style={{height: '22px'}} />}
        </div>
        {values['onsiteFrequency'] !== 'not_interested' && (
          <div className="my-px">
            <Label className="font-normal text-darkest-navy text-sm sm:text-base">{$.travel_distance_label}</Label>
            <Sublabel>{$.travel_distance_sublabel}</Sublabel>
            <div className="my-px">
              {TRAVEL_DISTANCE_OPTIONS.map((option) => 
                <Checkbox
                  outlined
                  key={option.value}
                  value={values['travelDistancePreferences']?.includes(option.value) ? true : false}
                  onChange={(v) => onCheckboxClick('travelDistancePreferences', option.value, v)}
                >
                  <div className="ml-1 my-1 font-sm text-darkest-navy font-lato">{option.label}</div>
                </Checkbox>
              )}
            </div>
            {(!touched.travelDistancePreferences && errors.travelDistancePreferences) ? <Error msg={errors.travelDistancePreferences} /> : <div style={{height: '22px'}} />}
          </div>
        )}
      </div>
    </div>
  )
};

export default OpportunityPreferences;
