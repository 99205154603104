import * as yup from "yup";

export const yupPasswordSchema = yup.string()
    .required("This field is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    // .matches(/[!@#$%^&*()_+]/, "Password must contain at least one special character")
    .matches(/[^A-Za-z0-9]/, "Password must contain at least one special character");
