import React, {useEffect, useState} from "react";
import $ from "strings/talent";
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams
} from "react-router-dom";
import {withHttp} from "utils/str";
import Logo from "components/Logo";
import Section from "components/Section";
import userImg from "assets/user.svg";
import ProfilePage from "./Profile";
import SettingsPage from "./Settings";
import AssessmentsPage from "./Assessments";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";
import {
  clearLoginToken, getAccessToken,
  getTalentOnboarded, getUrlSuffix,
  getUserId
} from "../../utils/localStorageService";
import {getTalentDataFromUrlSuffix} from 'utils/api';

const RESOURCES_URL = 'https://learn.rightsideup.com/contractor-resources/?utm_medium=product&utm_source=direct&utm_campaign=talent_profile&utm_content=talent&utm_term=talent_profile'
const TIMESHEETS_URL = 'https://app.clockify.me/tracker'

const Navbar = () => {
  const [logged, setLogged] = useState(!!getAccessToken());
  const [drawerOpen, setDrawerOpen] = useState(false);
  const history = useHistory();

  const userSuffix = getUrlSuffix();

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (drawerOpen) {
        setDrawerOpen(false);
      }
    });
  });

  const getNavTextColor = (path) => {
    return window.location.pathname.includes(path) ? 'text-electric-indigo' : 'text-nav-blue';
  }

  return (
    <div className="bg-white flex h-13 mx-4 mt-4 px-3.5 py-2.5 rounded-lg items-center select-none">
      <div
        className="flex items-center mr-8 h-full cursor-pointer"
        onClick={() => history.push("/talent")}
      >
        <Logo />
      </div>
      {!logged ? <></> : <>
      <div className="flex-1 min-w-0 items-center">
        <div className="text-base text-nav-blue font-bold flex-1 flex gap-4 mx-0 my-auto">
          <NavLink to={`/talent/${userSuffix}/profile`} className={getNavTextColor('profile')}>
            {$.profile_navlink}
          </NavLink>
          <NavLink to={`/talent/${userSuffix}/niche`} className={getNavTextColor('niche')}>
            {$.niche_navlink}
          </NavLink>
          <NavLink to={{pathname: withHttp(RESOURCES_URL)}} target="_blank">
            {$.resource_navlink}
          </NavLink>
          <NavLink to={{pathname: withHttp(TIMESHEETS_URL)}} target="_blank">
            {$.timesheets_navlink}
          </NavLink>
        </div>
      </div>
      <div
        className="h-full ml-auto flex items-center cursor-pointer"
        onClick={e => {
          e.stopPropagation();
          setDrawerOpen(!drawerOpen);
        }}
      >
        <img
          src={userImg}
          className="rounded-full h-full w-auto mr-1.25"
          // alt={fullname}
        />
        {drawerOpen ? <UilUp size="20" /> : <UilDown size="20" />}
        {drawerOpen ?
          <div className="flex flex-col absolute z-10 top-14 right-2 p-2 bg-white border border-lightest-grey rounded-md text-sm text-nav-blue cursor-pointer">
            <NavLink className="p-1 rounded-sm hover:bg-link-water" to={`/talent/${userSuffix}/settings`}>
              {$.settings_navlink}
            </NavLink>
            <a
              className="p-1 rounded-sm hover:bg-link-water"
              href="https://rightsideup.slite.page/p/8oZZhIIjSmmEoH/Talent-Knowledge-Portal"
              target="_blank"
              rel="noreferrer"
            >
              {$.support_navlink}
            </a>
            <NavLink
              className="p-1 rounded-sm hover:bg-link-water"
              onClick={() => {
                clearLoginToken();
                window.location.href = "/login"; // requires full page reload
              }}
              to={`/login`}
            >
              {$.logout_navlink}
            </NavLink>
          </div> :
          <></>
        }
      </div>
      </>}
    </div>
  )
}

const Page = () => {
  const { id: suffix } = useParams();
  const [talentData, setTalentData] = useState();
  const [isPublicProfile, setIsPublicProfile] = useState(true);
  const [loggedInUserId, setLoggedInUserId] = useState(getUserId());
  const userSuffix = getUrlSuffix();

  const history = useHistory();

  useEffect(() => {
    const onboarded = getTalentOnboarded();
    const userId = getUserId();
    if (!userId) {
      return;
    }
    if (!onboarded) {
      history.push(`/talent/onboard?user=${userId}`);
    }
  }, []);

  useEffect(() => {
    if (!suffix || suffix === 'null') {
      window.location.href = '/talent';
    }
    try {
      getTalentDataFromUrlSuffix(suffix).then(response => {
        setTalentData(response);
      });
    } catch (e) {
      console.error(e);
    }

  }, [suffix]);

  useEffect(() => {
    const forcePublic = window?.location?.search?.includes('public=true');
    setIsPublicProfile(talentData?.user?.id !== loggedInUserId || forcePublic);
  }, [talentData, loggedInUserId]);

  return (
    <Section
      id="talent_tech_page"
      className="font-lato bg-alice-blue-lighter min-h-full flex flex-col"
    >
      <div className="flex-1 justify-center">
        <Navbar />
        <Switch>
          <Route exact path="/talent/:id">
            <ProfilePage talentData={talentData} setTalentData={setTalentData} isPublicProfile={isPublicProfile} />
          </Route>
          <Route exact path="/talent/:id/profile">
            <ProfilePage talentData={talentData} setTalentData={setTalentData} isPublicProfile={isPublicProfile} />
          </Route>
          <Route exact path="/talent/:id/settings">
            {suffix === userSuffix ?
              <SettingsPage talentData={talentData} setTalentData={setTalentData} isPublicProfile={isPublicProfile} />
              : userSuffix ?
              <Redirect to={`/talent/${userSuffix}/settings`} />
              : <>404</>}
          </Route>
          <Route exact path="/talent/:id/niche">
            {suffix === userSuffix ?
              <AssessmentsPage talentData={talentData} setTalentData={setTalentData} isPublicProfile={isPublicProfile} />
              : userSuffix ?
              <Redirect to={`/talent/${userSuffix}/niche`} />
              : <>404</>}
          </Route>
          <Redirect from="/talent/:id" to="/talent/:id/profile" />
        </Switch>
      </div>
    </Section>
  );
};

export default Page;
