import React from "react";

const StarIcon = ({
  isSelected,
  size = 22,
}) => {
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.795 0.716438L14.6404 5.73409L20.089 7.00972C21.1263 7.25255 21.5354 8.56738 20.8328 9.40063L17.1427 13.7774L17.6648 19.5834C17.7642 20.6888 16.6932 21.5014 15.7174 21.0611L10.5915 18.7484L5.46548 21.0611C4.48959 21.5014 3.41866 20.6888 3.51808 19.5834L4.04015 13.7774L0.350081 9.40063C-0.352438 8.56738 0.0566005 7.25259 1.09392 7.00972L6.54252 5.73409L9.38789 0.716438C9.9296 -0.238813 11.2533 -0.238813 11.795 0.716438Z" fill={isSelected ? "#3049C5" : "#e8ebf8"} />
    </svg>
  )
};

export default StarIcon;
