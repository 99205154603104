import $ from "../../../strings/talent.json";
import clsx from "clsx";
import React, {useEffect, useState} from "react";
import {AddBtn, EditBtn, HideBtn} from "components/Buttons";
import ImportRSUProjectForm from "./ImportRSUProjectForm";
import {
  getTalentDataFromUrlSuffix,
  patchTalentTalentCreatedProject
} from "../../../utils/api";
import AddTalentProjectForm from "./AddTalentProjectForm";
import Logo from "../../../components/Logo";
import {PointedTooltip} from "../components";
import {LinkPreview} from "./LinkPreview";
import {getProjectCompletion} from "../../../utils/misc";
import completeness_red from "../assets/completeness_red.png";
import {
  QualificationsDisplay
} from "../../../components/QualificationsDisplay";

const Projects = ({ talentData, setTalentData, isPublicProfile, setAddNewProject }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [addRSUOpen, setAddRSUOpen] = useState(false);
  const [addNonRSUOpen, setAddNonRSUOpen] = useState(false);
  const [talentProjects, setTalentProjects] = useState({});
  const [modalWasOpen, setModalWasOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});
  const [showHideProjectTooltip, setShowHideProjectTooltip] = useState('');
  const [RSUProjects, setRSUProjects] = useState({});

  window.document.onclick = () => {
    setDrawerOpen(false);
  };

  window.document.onkeydown = e => {
    if (e.key === "Escape") {
      setDrawerOpen(false);
    }
  };

  useEffect(() => {
    if (setAddNewProject) {
      setAddNewProject(() => () => setAddNonRSUOpen(true));
    }
  }, [setAddNewProject]);

  useEffect(() => {
    // populate talentProjects
    const existingProjectIds = {};
    if (talentData?.talent_created_projects) {
      const projects = {};
      for (const project of talentData.talent_created_projects) {
        if (isPublicProfile && project.is_hidden) {
          // skip hidden projects in public profile
          // only relevant when talent is viewing their own profile in public mode
          // because when not authenticated, those projects are not fetched
          continue;
        }
        existingProjectIds[project.project_id] = true;
        const key = project.company?.name || 'Company unknown';
        if (!projects[key]) {
          projects[key] = [];
        }
        projects[key].push(project);
      }
      setTalentProjects(projects);
    }

    // populate RSUProjects
    if (talentData?.rsu_projects) {
      const projectObject = {};
      for (const project of talentData?.rsu_projects) {
        if (!existingProjectIds[project.id]) {
          projectObject[project.id] = project;
        }
      }
      setRSUProjects(projectObject);
    }
  }, [talentData, isPublicProfile]);

  useEffect(() => {
    // setModalWasOpen to true if either modal is open so that we know to refresh the talent data
    if (addRSUOpen || addNonRSUOpen) {
      setModalWasOpen(true);
    }
  }, [addRSUOpen, addNonRSUOpen]);

  useEffect(() => {
    // refresh talent data if modal was open and is now closed because the user might have added/changed a project
    if (!addRSUOpen && !addNonRSUOpen && modalWasOpen) {
      getTalentDataFromUrlSuffix(talentData.url_suffix).then(setTalentData);
    }
  }, [addRSUOpen, addNonRSUOpen, modalWasOpen]);

  const getProjectTime = project => {
    const simpleDate = date => {
      const d = new Date(date);
      return d.toLocaleString('default', { month: 'short' }) + ' ' + d.getFullYear();
    };

    if (project.start_date && project.end_date) {
      let months = Math.round((new Date(project.end_date) - new Date(project.start_date)) / (1000 * 60 * 60 * 24 * 30));
      let years = Math.floor(months / 12);
      let time;
      if (years > 0) {
        months = months % 12;
        time = `${years} yr${years > 1 ? 's' : ''}`;
        if (months > 0) {
          time += ` ${months} mo${months > 1 ? 's' : ''}`;
        }
      } else if (months > 0) {
        time = `${months} mo${months > 1 ? 's' : ''}`;
      } else {
        time = 'Less than a month';
      }
      return `${simpleDate(project.start_date)} - ${simpleDate(project.end_date)} · ${time}`;
    } else if (project.start_date && !project.end_date) {
      return `Since ${simpleDate(project.start_date)}`;
    } else if (!project.start_date && project.end_date) {
      return `Until ${simpleDate(project.end_date)}`;
    } else {
      return 'No information about project time';
    }
  };

  const getProjectInfo = project => {
    let size = project.employees;
    if (size && !size.includes(' employees')) {
      size += ' employees';
    }
    if (project.commitment && size) {
      return `${project.commitment} · ${size}`;
    } else if (project.commitment && !size) {
      return project.commitment;
    } else if (!project.commitment && size) {
      return size;
    } else {
      return 'No information';
    }
  };

  const DrawerElement = ({className, onClick, children, disabled=false, ...props}) => (
    <div
      className={clsx(
        'text-sm rounded-sm p-1 select-none cursor-pointer hover:bg-lightest-grey',
        disabled ? 'text-caded cursor-not-allowed' : '',
        className)}
      onClick={disabled ? e => e.stopPropagation() : onClick}
      {...props}
    >
      {children}
    </div>
  );

  return (
    <div className="bg-white rounded-lg p-4">
      <div className="relative flex mb-4 h-7.5">
        <div className="text-lg font-bold">{$.profile_projects_title}</div>
        <div
          className="h-7.5 ml-auto"
          onClick={e => {
            e.stopPropagation();
            setDrawerOpen(!drawerOpen);
          }}
          hidden={isPublicProfile}
        >
          <AddBtn className="w-full"/>
        </div>
        {!isPublicProfile && drawerOpen && (
          <div
            className="absolute text-kasmir right-0 -bottom-20 rounded-lg bg-white border border-lightest-grey p-1"
          >
            <DrawerElement
              className="mb-px"
              title={Object.keys(RSUProjects).length === 0 ? 'No RSU projects available to import' : null}
              disabled={Object.keys(RSUProjects).length === 0}
              onClick={e => {
                if (Object.keys(RSUProjects).length === 0) {
                  e.stopPropagation();
                  alert.error('No RSU projects available to import');
                } else {
                  e.stopPropagation();
                  setAddRSUOpen(true);
                  setDrawerOpen(false);
                }
              }}
            >Import RSU Project</DrawerElement>
            <DrawerElement
              onClick={e => {
                e.stopPropagation();
                setAddNonRSUOpen(true);
                setSelectedProject({});
                setDrawerOpen(false);
              }}
            >Add non-RSU Project</DrawerElement>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2.5 text-midnight">
        {Object.entries(talentProjects).map(([companyName, projects], i) => (
          <div key={i} className="flex flex-col bg-lightest-grey rounded-lg p-3.5">
            <div className="mb-2.5 h-15 flex flex-row gap-x-2.5">
              {!!projects[0]?.company?.logo ? (
                <img
                  className="w-15 h-15 rounded-lg"
                  src={projects[0].company.logo}
                  alt="logo"
                />
              ) : (
                <div className="h-15 w-15 bg-blue-200 rounded-lg flex">
                </div>
              )}
              <div className="my-auto flex-auto">
                <div className="text-base font-bold">{companyName}</div>
                <div className="text-sm text-kasmir">{projects.length} project{projects.length > 1 ? 's' : ''}</div>
              </div>
            </div>
            {projects.map((project, i) => (
            <div className="bg-white rounded-lg mb-2.5 ml-16 p-4 flex flex-col gap-3" key={i}>
              <div className="flex flex-row">
                <div className={clsx(project.is_hidden ? 'opacity-50' : '')}>
                  <div className="flex flex-row gap-x-2.5 mr-auto">
                    <div className="text-base font-bold">{project.name}</div>
                    {!isPublicProfile && !!project.project_id && (
                      <div
                        className="text-sm text-kasmir bg-alice-blue rounded-full w-6.5 h-6.5 flex"
                      ><Logo className="w-4 mt-px mx-auto"/></div>
                    )}
                    <div
                      className="flex"
                      title={`This project is only ${getProjectCompletion(project)}% complete`}
                    >
                      {getProjectCompletion(project) <= 20 ? (
                        <img
                          className="my-auto"
                          src={completeness_red}
                          alt="completeness"
                        />
                      ) : <></>}
                    </div>
                  </div>
                  <div className="text-sm text-kasmir">{getProjectTime(project)}</div>
                  <div className="text-sm text-kasmir">{getProjectInfo(project)}</div>
                </div>
                {!isPublicProfile && (<div className="flex ml-auto gap-x-2.5">
                  <EditBtn onClick={() => {
                    setAddNonRSUOpen(true);
                    setSelectedProject(project);
                  }}/>
                  <PointedTooltip
                    onClick={e => e.stopPropagation()}
                    hidden={showHideProjectTooltip !== project.id || project.is_hidden}
                    msg={$.hide_project_tooltip_msg}
                  >
                    <HideBtn
                      onMouseEnter={e => {
                        e.stopPropagation();
                        setShowHideProjectTooltip(project.id);
                      }}
                      onMouseLeave={e => {
                        e.stopPropagation();
                        setShowHideProjectTooltip('');
                      }}
                      show={!!project.is_hidden}
                      onClick={async () => {
                        await patchTalentTalentCreatedProject(project.id, {is_hidden: !project.is_hidden});
                        const newTalentData = await getTalentDataFromUrlSuffix(talentData.url_suffix);
                        setTalentData(newTalentData);
                      }}
                    />
                  </PointedTooltip>
                </div>)}
              </div>
              <div className={clsx("text-kasmir text-sm", project.is_hidden ? 'opacity-50' : '')}>
                {project.results || 'No description'}
              </div>
              {(project.links || project.attached_files) && (<div className={clsx("flex flex-col gap-y-2.5", project.is_hidden ? 'opacity-50' : '')}>
                {project.links.map((link, i) => (
                  <LinkPreview className="mr-auto" name={link.name} key={i} />
                ))}
                {project.attached_files.map((file, i) => (
                  <LinkPreview className="mr-auto" name={file.filename} key={i} />
                ))}
              </div>)}
              {!!project.skills_and_tools && (<div className={clsx(project.is_hidden ? 'opacity-50' : '')}>
                <div className="text-sm font-bold mb-2">{$.skills_and_tools_subtitle}</div>
                {project.skills_and_tools && <QualificationsDisplay qualifications={project.skills_and_tools} />}
              </div>)}
            </div>
            ))}
          </div>
        ))}
      </div>
      {addRSUOpen &&
        <ImportRSUProjectForm
          setIsOpen={setAddRSUOpen}
          RSUProjects={RSUProjects}
        />}
      {addNonRSUOpen && (
        <AddTalentProjectForm
          talentData={talentData}
          setIsOpen={setAddNonRSUOpen}
          initialFormValues={selectedProject}
        />
      )}
    </div>
  )
};

export default Projects;
