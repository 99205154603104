import React, { useState, useCallback } from "react";
import Cropper from 'react-easy-crop';
import Modal from "../components/Modal";
import Slider from "../components/Slider";
import $ from "strings/talent";
import userImg from "assets/user.svg";
import { postTalentProfilePhoto, deleteTalentProfilePhoto } from "utils/api";
import { useDropzone } from "react-dropzone";
import { FileUploadIcon } from "../onboard/icons";
import getCroppedImg from "../utils/cropImage";

const ProfilePhotoForm = ({
  onClose,
  talentData,
  setTalentData
}) => {
  const { user: { profile_photo }} = talentData;
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const onDrop = useCallback((acceptedFiles) => {
    let reader = new FileReader();
    let f = acceptedFiles[0];
    reader.readAsDataURL(f);
    reader.onload = (e) => {
      setSelectedFile(e.target.result)
    };
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: [
      ".png",
      ".jpeg",
      ".jpg",
    ],
    maxSize: 50000000,
    multiple: false,
  });

  const onClickSave = async () => {
    const userId = talentData?.user?.id;
    if (!userId) return;
    const originalValues = { ...talentData };
    setIsUpdating(true);
    
    const croppedImage = await getCroppedImg(selectedFile, croppedAreaPixels, 0);
    const toSend = {
      user: userId,
      profile_photo_encoded: croppedImage
    };

    try {
      const response = await postTalentProfilePhoto(toSend);
      setTalentData({ ...talentData, user: {...talentData.user, profile_photo: response.profile_photo }})
      setIsUpdating(false);
      onClose();
      return response;
    } catch (e) {
      setTalentData({ ...originalValues })
      setIsUpdating(false);
      console.error({e})
    }
  };

  const onClickCancel = () => {
    setSelectedFile();
    onClose();
  }

  const onClickDelete = () => {
    setShowConfirmDelete(true)
  }

  const onClickConfirmDelete = async () => {
    const originalValues = { ...talentData }
    setIsUpdating(true);
    const userId = talentData?.user?.id;
    if (!userId) return;

    try {
      await deleteTalentProfilePhoto({ user: userId })
      setTalentData({ ...talentData, user: {...talentData.user, profile_photo: null }})
      setIsUpdating(false);
      setSelectedFile();
      onClose();
    } catch (e) {
      setTalentData({ ...originalValues })
      setIsUpdating(false);
      console.error({e})
    }

  }

  const onUploadImage = (e) => {
    const imageUploader = document.querySelector("input");
    let reader = new FileReader();
    reader.readAsDataURL(imageUploader.files[0]);
    reader.onload = (e) => {
      setSelectedFile(e.target.result)
    };
  }

  return (
    <>
      {showConfirmDelete ?
        <Modal
          title='Delete Photo'
          onClose={() => setShowConfirmDelete(false)}
          onClickCancel={() => setShowConfirmDelete(false)}
          onClickSave={onClickConfirmDelete}
          buttonsDisabled={isUpdating}
          saveBtnText='Delete Photo'
          id="delete_profile_photo_confirm"
        >
          <div className="flex flex-col text-sm p-4 gap-2">
            <div>{$.confirm_delete_photo_helper}</div>
            <div>{$.are_you_sure_helper}</div>
          </div>
        </Modal> :
        <Modal
          title={$.profile_photo_title}
          onClose={onClickCancel}
          onClickCancel={onClickCancel}
          onClickSave={onClickSave}
          buttonsDisabled={isUpdating}
          saveBtnText={$.attach_photo_btn}
          showDelete={true}
          deleteBtnText={$.delete_photo_btn}
          onClickDelete={onClickDelete}
          id="profile_photo_form"
        >
          <div className="flex-1 min-h-0 overflow-y-auto p-4">
            <div className="flex gap-2">
              <div className="w-1/4">
                <img style={{ height: "150px", width: "150px" }} className="border-1 border-kasmir rounded-lg" src={profile_photo || userImg} />
              </div>
              <div className="flex flex-col w-3/4 ">
                <div {...getRootProps()} style={{ height: '250px' }} className="flex flex-col justify-center text-center justify-center align-center border border-geyser rounded-lg">
                  <input {...getInputProps()} onChange={onUploadImage} />
                  {isDragActive ? (
                    <div>{$.drop_files_message}</div>
                    ) : selectedFile ? (
                      <div style={{ height: '250px' }} className="relative flex flex-col justify-center items-center">
                        <Cropper
                          image={selectedFile}
                          crop={crop}
                          zoom={zoom}
                          aspect={1}
                          cropShape="square"
                          onCropChange={setCrop}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                        />
                      </div>
                    ) : (
                    <div className="flex flex-col justify-center items-center gap-2">
                      <div>
                        <FileUploadIcon />
                      </div>
                      <div className="text-sm">Drag and drop or <span className="text-electric-indigo cursor-pointer">choose a file</span> to upload</div>
                      <span className="text-xs text-kasmir">Maximum file size 50 MB.</span>
                    </div>
                  )}
                </div>
                {selectedFile && 
                  <Slider zoom={zoom} setZoom={setZoom} min={1} max={3} step={0.1} />
                }
              </div>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

export default ProfilePhotoForm;
