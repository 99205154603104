import React from 'react';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import UilMinus from '@iconscout/react-unicons/icons/uil-minus';

const Slider = ({
  zoom,
  setZoom,
  min,
  max,
  step
}) => {
  return (
    <div className="flex gap-2 pt-4 justify-center items-center w-full">
      <UilMinus className="text-electric-indigo" size="12" />
      <input
        className="w-full h-1 border-t-white border-b-white bg-electric-indigo"
        type="range"
        value={zoom}
        min={min}
        max={max}
        step={step}
        aria-labelledby="Zoom"
        onChange={(e) => {
          setZoom(e.target.value)
        }}
      />
      <UilPlus className="text-electric-indigo" size="12" />
    </div>
  );
};
  
export default Slider;
