import React, {useState} from 'react';
import file_upload_icon from "../../../assets/file-upload.svg";
import clsx from "clsx";


export const FileInput = ({
                            className,
                            onChange,
                            multiple,
                            disabled,
                            disabledMsg,
                            filename,
                            maxSize = 50,
                            ...props
                          }) => {
  const [fileName, setFileName] = useState(filename || '');
  const [fileError, setFileError] = useState('');

  return (
    <div
      className={clsx("flex justify-center h-37.5 p-4 rounded-md border border-geyser border-dashed", className)}>
      <label htmlFor="file-upload"
             className={clsx("flex", disabled ? "cursor-not-allowed" : "cursor-pointer")}>
        <input
          disabled={disabled}
          type="file"
          id="file-upload"
          className="sr-only"
          multiple={!!multiple}
          onChange={e => {
            if (e.target?.files?.length) {
              // check file size on e.target.files
              for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i].size > maxSize * 1024 * 1024) {
                  setFileError(`File size exceeds ${maxSize} MB`);
                  return;
                }
              }
              const value = multiple ? e.target.files : e.target.files[0];
              setFileName(value.name);
              onChange(value);
            } else {
              setFileName('');
              onChange(null);
            }
          }}
          onDrop={(acceptedFiles) => {
            const value = multiple ? acceptedFiles : acceptedFiles[0];
            onChange(value);
            setFileName(value.name);
          }}
        />
        <div className="text-center my-auto">
          {disabled ? (<>
              {fileName && (<p className="text-sm text-kasmir font-semibold mb-1">{fileName}</p>)}
              <p className="text-kasmir text-sm font-semibold">
                {disabledMsg || 'File upload disabled'}
              </p>
            </>)
            : fileError ? (
                <p className="text-red text-sm font-semibold">
                  {fileError}
                </p>)
              :
              !fileName ? (<>
                <img
                  className="mb-2 mx-auto"
                  src={file_upload_icon}
                  alt="No results"
                />
                <p className="text-midnight text-sm mb-1 font-semibold">
                  <span className=" mb-2">Drag & Drop or&nbsp;</span>
                  <span className="text-electric-indigo font-semibold mb-2">Choose file{multiple ? '(s)' : ''}</span>
                  <span>&nbsp;to upload</span>
                </p>
                <p className="text-kasmir text-xs">Maximum file
                  size {`${JSON.stringify(maxSize)}`} MB.
                </p>
              </>) : (
                <p className="text-sm font-semibold">{fileName}</p>
              )}
        </div>
      </label>
    </div>
  );
};
