import React from "react";
import $ from "../../../strings/talent.json";
import Button from "./Button";


export const SettingsActionBar = ({
  cancelDisabled=false,
  saveDisabled=false,
  onCancel,
  onSave,
  className="",
  ...props
}) => {
  return (
    <div className={`flex flex-row my-2 ${className}`}>
      <Button
        secondary
        className="text-sm sm:text-base mr-2"
        disabled={cancelDisabled}
        onClick={onCancel}
      >
        <span>{$.cancel_btn}</span>
      </Button>
      <Button
        className="text-sm sm:text-base rounded"
        disabled={saveDisabled}
        onClick={onSave}
      >
        <span>{$.save_btn}</span>
      </Button>
    </div>
  );
};
