import React, {useState} from "react";
import clsx from 'clsx';
import $ from "strings/talent";
import $$ from "strings/talent";
import {NicheIcon} from "pages/talent/onboard/icons";
import ContactInformation from "./ContactInformation";
import LoginCredentials from "./LoginCredentials";
import EmploymentStatus from "./EmploymentStatus";
import OpportunityPreferences from "./OpportunityPreferences";
import SearchEngineOptimization from "./SearchEngineOptimization";
import Availability from "./Availability";
import Experience from "./Experience";

const SETTINGS_SCREENS = {
  "contact": {
    icon: <NicheIcon />,
    activeIcon: <NicheIcon active={true} />,
    title: $.contact_title
  },
  "credentials": {
    icon: <NicheIcon />,
    activeIcon: <NicheIcon active={true} />,
    title: $.credentials_title
  },
  "profile_settings": {
    icon: <NicheIcon />,
    activeIcon: <NicheIcon active={true} />,
    title: $.profile_settings_title,
    subsections: {
      "seo": {
        title: $$.seo_section_title,
        page_title: $$.seo_page_title,
      },
      "employment": {
        title: $$.employment_status_title,
      },
      "opportunity": {
        title: $$.opportunity_preferences_title,
      },
      "availability": {
        title: $$.availability_title,
      },
      "experience": {
        title: $$.experience_title,
      }
    }
  }
};

const SettingsPage = ({talentData, setTalentData}) => {
  const [activeScreen, setActiveScreen] = useState('contact');
  const [activeSubsection, setActiveSubsection] = useState('seo');

  const onNavClick = (screen) => {
    if (activeScreen === 'profile_settings' && screen !== 'profile_settings') {
      setActiveSubsection('employment')
    }
    setActiveScreen(screen)
  };

  const getPageSubtitle = () => {
    if (SETTINGS_SCREENS[activeScreen].subsections) {
      const subsection = SETTINGS_SCREENS[activeScreen].subsections[activeSubsection];
      return subsection.page_title || subsection.title;
    } else {
      return SETTINGS_SCREENS[activeScreen].title
    }
  }

  const onSubsectionClick = (subsection) => {
    setActiveSubsection(subsection)
  };

  const NavItem = ({name, onNavClick}) => {
    return (
      <div
        className={clsx("flex flex-col justify-center w-full sm:py-2 ", activeScreen === name ? "flex-3 sm:flex-1" : "flex-1")}>
        <div
          className={clsx("flex cursor-pointer items-center p-3", (activeScreen === name && !SETTINGS_SCREENS[name].subsections) && "sm:bg-lightest-grey sm:rounded-lg ", (activeScreen === name) && " text-electric-indigo")}
          onClick={() => onNavClick(name)}
        >
          <div className="h-full bg-lightest-grey sm:bg-transparent rounded-lg pr-2">
            {activeScreen === name ? SETTINGS_SCREENS[name].activeIcon : SETTINGS_SCREENS[name].icon}
          </div>
          <div className={clsx("ml-2 text-sm sm:text-base font-semibold", activeScreen === name ? "text-electric-indigo " : "hidden sm:flex sm:text-kasmir")}>
            {SETTINGS_SCREENS[name].title}
          </div>
        </div>
        {(activeScreen === name && SETTINGS_SCREENS[name].subsections) &&
          <div className="flex flex-col my-2">
            {Object.keys(SETTINGS_SCREENS[name].subsections).map(subsection =>
              <div
                key={subsection}
                className="cursor-pointer"
                onClick={() => onSubsectionClick(subsection)}
              >
                <div className={
                  clsx("flex ml-8 py-2 font-medium text-sm text-kasmir ",
                  activeSubsection === subsection && "bg-lightest-grey rounded-lg text-electric-indigo")
                }>
                  <div className="pl-2">{SETTINGS_SCREENS[name].subsections[subsection].title}</div>
                </div>
              </div>
            )}
          </div>
        }
      </div>
    )
  };

  const Header = ({title, className}) => {
    return (
      <div
        className={`text-2xl font-bold ${className}`}
      >{title}</div>
    )
  };

  return (
    <div className="flex p-4">
      <div className="w-1/4">
        <div className="bg-white rounded-xl p-4">
          <Header className="py-3" title={$.settings_navlink} />
          {Object.keys(SETTINGS_SCREENS).map(screen =>
            <NavItem key={screen} name={screen} onNavClick={onNavClick} />
          )}
        </div>
      </div>
      <div className="w-3/4 pl-4">
        <div className="bg-white rounded-xl p-4">
          <Header className="mt-3 mb-6" title={getPageSubtitle()} />
          <div className="w-full">
            {{
              'contact': <ContactInformation talentData={talentData} setTalentData={setTalentData} />,
              'credentials': <LoginCredentials talentData={talentData} setTalentData={setTalentData} />,
              'profile_settings': {
                'seo': <SearchEngineOptimization talentData={talentData} setTalentData={setTalentData} />,
                'employment': <EmploymentStatus talentData={talentData} setTalentData={setTalentData} />,
                'opportunity': <OpportunityPreferences talentData={talentData} setTalentData={setTalentData} />,
                'availability': <Availability talentData={talentData} setTalentData={setTalentData} />,
                'experience': <Experience talentData={talentData} setTalentData={setTalentData} />,
              }[activeSubsection]
            }[activeScreen] || <div />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
