import React, { useMemo } from "react";
import { EditBtn } from "components/Buttons";
import {
  QualificationsDisplay
} from "../../../components/QualificationsDisplay";

const HIDDEN_INDUSTRY_IDS = [
  '57ddc7ac-da7e-4eee-946c-0b697e0819e8' // International Talent - not user facing
];

const Industries = ({ talentData, isPublicProfile }) => {
  const { qualifications } = talentData;

  const industries = useMemo(() => {
    if (!qualifications) return;
    console.log('isPublicProfile', isPublicProfile);
    return qualifications.filter(
      q => HIDDEN_INDUSTRY_IDS.indexOf(q.id) === -1 &&
        q.qualification?.type.toLowerCase() === 'industry' &&
        (!isPublicProfile || !!q.display_on_talent_profile)
    ).map(q => q.qualification);
  }, [qualifications, isPublicProfile]);

  return (
    <div className="bg-white rounded-lg p-4 gap-y-3.5 flex flex-col">
      <div className="flex gap-2 items-center">
        <div className="text-lg font-bold">Industry Experience</div>
        {!isPublicProfile && <div className="ml-auto mr-0">
          <EditBtn />
        </div>}
      </div>
      {industries && <QualificationsDisplay qualifications={industries} large />}
    </div>
  )
};

export default Industries;
