import axios from "axios";
import {
  getAdminAccessToken,
  getAdminRefreshToken,
  setAdminToken,
  clearLoginToken,
} from "./localStorageService";
import history from "./history";

let setLogged;

const ax = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

ax.interceptors.request.use(
  (config) => {
    const token = getAdminAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

ax.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      (originalRequest._retry ||
        originalRequest.url.includes("auth/token/refresh/"))
    ) {
      clearLoginToken();
      setLogged(false);
      history.push("/login");
      return Promise.reject(error);
    }

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const refreshToken = getAdminRefreshToken();

      const res = await ax.post(
        "auth/token/refresh/",
        { refresh: refreshToken },
        { headers: { Authorization: "Basic Og==" } }
      );

      if (res.status === 200) {
        setAdminToken(res.data);

        return ax(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export const configureLoggedSetter = (setter) => {
  setLogged = setter;
};

export const oauth = async (token) => {
  return (await ax.post("oauth/", { token })).data;
};

export const getAccount = async (userId) => {
  return (await ax.get(`accounts/${userId}/`)).data;
};

export const getAccounts = async (params) => {
  return (await ax.get(`accounts/`, { params })).data;
};

export const patchAccount = async (userId, data) => {
  return (await ax.patch(`accounts/${userId}/`, data)).data;
};

export const postAccount = async (data) => {
  return (await ax.post(`accounts/`, data)).data;
};

export const getUser = async (userId) => {
  return (await ax.get(`accounts/user/${userId}`)).data;
};

export const getUsers = async (userId) => {
  return (await ax.get(`accounts/user/`)).data;
};

export const postUser = async (data) => {
  return (await ax.post(`accounts/user/`, data)).data;
};

export const patchUser = async (userId, data) => {
  return (await ax.patch(`accounts/user/${userId}/`, data)).data;
};

export const deleteUser = async (userId) => {
  return (await ax.delete(`accounts/user/${userId}/`)).data;
};

export const createTalentFromUser = async (userId) => {
  return (await ax.post(`accounts/user/${userId}/create_talent/`)).data;
};

export const getDescriptors = async (params) => {
  return (await ax.get("qualification_descriptors/", { params })).data;
};

export const getDescriptorOptions = async (params) => {
  return (await ax.get("qualification_descriptor_values/", { params })).data;
};

export const getQualifications = async (params) => {
  return (await ax.get("qualifications/", { params })).data;
};

export const getProject = async (projectId) => {
  return (await ax.get(`projects/${projectId}/`)).data;
};

export const getProjects = async (params) => {
  return (await ax.get(`projects/`, { params })).data;
};

export const postProject = async (data) => {
  return (await ax.post(`projects/`, data)).data;
};

export const patchProject = async (projectId, data) => {
  return (await ax.patch(`projects/${projectId}/`, data)).data;
};

export const getCandidate = async (candidateId) => {
  return (await ax.get(`projects/candidates/${candidateId}/`)).data;
};

export const getCandidates = async (params) => {
  return (await ax.get(`projects/candidates/`, { params })).data;
};

export const postCandidate = async (data) => {
  return (await ax.post(`projects/candidates/`, data)).data;
};

export const patchCandidate = async (candidateId, data) => {
  return (await ax.patch(`projects/candidates/${candidateId}/`, data)).data;
};

export const getCompany = async (companyId) => {
  return (await ax.get(`companies/${companyId}/`)).data;
};

export const getCompanies = async (params) => {
  return (await ax.get(`companies/`, { params })).data;
};

export const postCompany = async (data) => {
  return (await ax.post(`companies/`, data)).data;
};

export const patchCompany = async (id, data) => {
  return (await ax.patch(`companies/${id}/`, data)).data;
};

export const postProjectQualification = async (data) => {
  return (await ax.post(`companies/project_qualifications/`, data)).data;
};

export const patchProjectQualification = async (id, data) => {
  return (await ax.patch(`companies/project_qualifications/${id}/`, data)).data;
};

export const deleteProjectQualification = async (id) => {
  return (await ax.delete(`companies/project_qualifications/${id}/`)).data;
};

export const postContractScope = async (data) => {
  return (await ax.post(`projects/contracts_scope/`, data)).data;
};

export const deleteContractScope = async (id) => {
  return (await ax.delete(`projects/contracts_scope/${id}/`)).data;
};

export const getContact = async (contactId) => {
  return (await ax.get(`companies/contacts/${contactId}/`)).data;
};

export const getContacts = async (params) => {
  return (await ax.get(`companies/contacts/`, { params })).data;
};

export const postContact = async (data) => {
  return (await ax.post(`companies/contacts/`, data)).data;
};

export const patchContact = async (id, data) => {
  return (await ax.patch(`companies/contacts/${id}/`, data)).data;
};

export const deleteContact = async (id) => {
  return (await ax.delete(`companies/contacts/${id}/`)).data;
};

export const getStaffUsers = async (params) => {
  return (await ax.get(`/accounts/staff/`, { params })).data;
};

export const getStaffUser = async (user_id) => {
  return (await ax.get(`/accounts/staff/${user_id}`)).data;
};

export const getIntels = async (params) => {
  return (await ax.get(`companies/intel/`, { params })).data;
};

export const postIntel = async (data) => {
  return (await ax.post(`companies/intel/`, data)).data;
};

export const getContract = async (contractId) => {
  return (await ax.get(`companies/project_contracts/${contractId}/`)).data;
};

export const postContract = async (data) => {
  return (await ax.post(`companies/project_contracts/`, data)).data;
};

export const patchContract = async (id, data) => {
  return (await ax.patch(`companies/project_contracts/${id}/`, data)).data;
};

export const getContractDetails = async (project__id) => {
  const data = (
    await ax.get(`projects/extended_contracts/`, { params: { project__id } })
  ).data;

  if (data && Array.isArray(data.results) && data.results.length) {
    return data.results[0];
  }
};

export const postContractDetails = async (data) => {
  return (await ax.post(`projects/extended_contracts/`, data)).data;
};

export const patchContractDetails = async (id, data) => {
  return (await ax.patch(`projects/extended_contracts/${id}/`, data)).data;
};

export const getNextSteps = async (project__id) => {
  return (
    await ax.get(`projects/next_steps/`, {
      params: { project__id, page_size: 9999 },
    })
  ).data;
};

export const patchNextStep = async (id, data) => {
  return (await ax.patch(`projects/next_steps/${id}/`, data)).data;
};

export const postNextStep = async (data) => {
  return (await ax.post(`projects/next_steps/`, data)).data;
};

export const getStatusHistory = async (paramIds) => {
  const params = paramIds.split("_");
  return (
    await ax.get(`projects/status_history/`, {
      params: {
        candidate__user__id: params[0],
        project__id: params[1],
        page_size: 9999,
      },
    })
  ).data;
};

export const getTalentFiles = async (talent__user_id) => {
  return (
    await ax.get(`accounts/files/`, {
      params: { talent__user_id, page_size: 9999 },
    })
  ).data;
};

export const postTalentFile = async (data) => {
  return (await ax.post(`accounts/upload_file/`, data)).data;
};

export const deleteTalentFile = async (id) => {
  return (await ax.post(`accounts/delete_file/`, { id })).data;
};

export const getProjectFiles = async (project__id) => {
  return (
    await ax.get(`projects/files/`, {
      params: { project__id, page_size: 9999 },
    })
  ).data;
};

export const postProjectFile = async (data) => {
  return (await ax.post(`projects/upload_file/`, data)).data;
};

export const deleteProjectFile = async (id) => {
  return (await ax.post(`projects/delete_file/`, { id })).data;
};

export const postEmailInvite = async (talent_id) => {
  return (await ax.get(`email/invite/`, { params: { talent_id } })).data;
};

export const postTalentEmailInvite = async (data) => {
  return (await ax.post(`accounts/onboard_invite/`, data)).data;
};

export const postTalentNote = async (data) => {
  return (await ax.post(`accounts/talent_notes/`, data)).data;
};

export const patchTalentNote = async (id, data) => {
  return (await ax.patch(`accounts/talent_notes/${id}/`, data)).data;
};

export const patchStatusNote = async (id, data) => {
  return (await ax.patch(`projects/status_history_notes/${id}/`, data)).data;
};

export const deleteStatusNote = async (id) => {
  return (await ax.delete(`projects/status_history_notes/${id}/`)).data;
};

export const getTalentNotes = async (userId) => {
  const talentNotes = (
    await ax.get(`accounts/talent_notes/`, {
      params: { talent__user__id: userId, page_size: 9999 },
    })
  ).data;

  const statusNotes = (
    await ax.get(`projects/status_history_notes/`, {
      params: { candidate__user__id: userId, page_size: 9999 },
    })
  ).data;

  return [
    ...(talentNotes && Array.isArray(talentNotes.results)
      ? talentNotes.results
      : []),
    ...(statusNotes && Array.isArray(statusNotes.results)
      ? statusNotes.results.map((r) => ({
          body: r.description,
          created_on: r.created_at,
          id: r.id,
          created_by_id: r.updated_by,
          created_by: r.updated_by_name,
          updated_at: r.updated_at,
          isStatus: true,
          project: r.project,
          project_name: r.project_name,
        }))
      : []),
  ];
};

export const getProjectContractDetails = async (project__id) => {
  return (
    await ax.get(`companies/project_contracts_details/`, {
      params: { project__id },
    })
  ).data;
};

export const getProjectContract = async (project__id) => {
  return (
    await ax.get(`companies/project_contracts/`, {
      params: { project__id, page_size: 1 },
    })
  ).data;
};

export const getProjectNotes = async (project__id) => {
  const projectNotes = (
    await ax.get(`projects/notes/`, {
      params: { project__id, page_size: 9999 },
    })
  ).data;

  const statusNotes = (
    await ax.get(`projects/status_history_notes/`, {
      params: { project__id, page_size: 9999 },
    })
  ).data;

  return [
    ...(projectNotes && Array.isArray(projectNotes.results)
      ? projectNotes.results
      : []),
    ...(statusNotes && Array.isArray(statusNotes.results)
      ? statusNotes.results.map((r) => ({
          body: r.description,
          created_on: r.created_at,
          id: r.id,
          created_by_id: r.updated_by,
          created_by: r.updated_by_name,
          updated_at: r.updated_at,
          isStatus: true,
          candidate: r.candidate,
          candidate_name: r.candidate_name,
        }))
      : []),
  ];
};

export const postProjectNote = async (data) => {
  return (await ax.post(`projects/notes/`, data)).data;
};

export const patchProjectNote = async (id, data) => {
  return (await ax.patch(`projects/notes/${id}/`, data)).data;
};

export const postCompanyNote = async (data) => {
  return (await ax.post(`companies/company_notes/`, data)).data;
};

export const patchCompanyNote = async (id, data) => {
  return (await ax.patch(`companies/company_notes/${id}/`, data)).data;
};

export const getCompanyNotes = async (company__id) => {
  return (
    await ax.get(`companies/company_notes/`, {
      params: { company__id, page_size: 9999 },
    })
  ).data;
};

export const postContactNote = async (data) => {
  return (await ax.post(`companies/contact_notes/`, data)).data;
};

export const patchContactNote = async (id, data) => {
  return (await ax.patch(`companies/contact_notes/${id}/`, data)).data;
};

export const getContactNotes = async (contact__id) => {
  return (
    await ax.get(`companies/contact_notes/`, {
      params: { contact__id, page_size: 9999 },
    })
  ).data;
};

export const postProjectOwner = async (data) => {
  return (await ax.post(`projects/project_owners/`, data)).data;
};

export const deleteProjectOwner = async (id) => {
  return (await ax.delete(`projects/project_owners/${id}/`)).data;
};

export const getTalentOwners = async (talent__user__id) => {
  return (
    await ax.get(`accounts/talent_owners/`, { params: { talent__user__id } })
  ).data;
};

export const postTalentOwner = async (data) => {
  return (await ax.post(`accounts/talent_owners/`, data)).data;
};

export const deleteTalentOwner = async (id) => {
  return (await ax.delete(`accounts/talent_owners/${id}/`)).data;
};

export const getTalentLinks = async (user__user_id) => {
  return (await ax.get(`accounts/talent_links/`, { params: { user__user_id } }))
    .data;
};

export const getCompanyLinks = async (company__id) => {
  return (await ax.get(`companies/company_links/`, { params: { company__id } }))
    .data;
};

export const patchCompanyLink = async (id, data) => {
  return (await ax.patch(`companies/company_links/${id}/`, data)).data;
};

export const deleteCompanyLink = async (id) => {
  return (await ax.delete(`companies/company_links/${id}/`)).data;
};

export const postCompanyLink = async (data) => {
  return (await ax.post(`companies/company_links/`, data)).data;
};

export const getContactLinks = async (contact__id) => {
  return (await ax.get(`companies/contact_links/`, { params: { contact__id } }))
    .data;
};

export const patchContactLink = async (id, data) => {
  return (await ax.patch(`companies/contact_links/${id}/`, data)).data;
};

export const deleteContactLink = async (id) => {
  return (await ax.delete(`companies/contact_links/${id}/`)).data;
};

export const postContactLink = async (data) => {
  return (await ax.post(`companies/contact_links/`, data)).data;
};

export const postTalentLink = async (data) => {
  return (await ax.post(`accounts/talent_links/`, data)).data;
};

export const patchTalentLink = async (id, data) => {
  return (await ax.patch(`accounts/talent_links/${id}/`, data)).data;
};

export const deleteTalentLink = async (id) => {
  return (await ax.delete(`accounts/talent_links/${id}/`)).data;
};

export const getUserLinks = async (user_id) => {
  return (await ax.get(`accounts/user_links/`, {params: { user_id }}))
}

export const postUserLink = async (data) => {
  return (await ax.post(`accounts/user_links/`, data)).data;
};

export const patchUserLink = async (id, data) => {
  return (await ax.patch(`accounts/user_links/${id}`, data)).data;
};

export const deleteUserLink = async (id) => {
  return (await ax.delete(`accounts/user_links/${id}/`)).data;
};

export const getProjectQualifications = async (project) => {
  return (
    await ax.get(`companies/project_qualifications/`, {
      params: { project },
    })
  ).data;
};

export const getTalentQualifications = async (user__user_id, params) => {
  return (
    await ax.get(`accounts/talent_qualifications/`, {
      params: { user__user_id, ...params },
    })
  ).data;
};

export const postTalentQualification = async (data) => {
  return (await ax.post(`accounts/talent_qualifications/`, data)).data;
};

export const patchTalentQualification = async (id, data) => {
  return (await ax.patch(`accounts/talent_qualifications/${id}/`, data)).data;
};

export const deleteTalentQualification = async (id) => {
  return (await ax.delete(`accounts/talent_qualifications/${id}/`)).data;
};

export const getTalentProjects = async (user__user_id, params) => {
  return (
    await ax.get(`accounts/talent_projects/`, {
      params: { user__user_id, ...params },
    })
  ).data;
};

export const postTalentProject = async (data) => {
  return (await ax.post(`accounts/talent_projects/`, data)).data;
};

export const patchTalentProject = async (id, data) => {
  return (await ax.patch(`accounts/talent_projects/${id}/`, data)).data;
};

export const deleteTalentProject = async (id) => {
  return (await ax.delete(`accounts/talent_projects/${id}/`)).data;
};

export const getCandidateProjects = async (params) => {
  return (await ax.get(`accounts/projects/`, { params })).data;
};

export const getFlags = async () => {
  return (await ax.get(`flags/`)).data;
};

export const getLeadSources = async (params) => {
  return (await ax.get(`projects/lead_sources/`, { params })).data;
};

export const getLeadSource = async (id) => {
  return (await ax.get(`projects/lead_sources/${id}/`)).data;
};

export const getTalentLeadSource = async (id) => {
  return (await ax.get(`accounts/lead_sources/${id}/`)).data;
};

export const getTalentLeadSources = async (params) => {
  return (await ax.get(`accounts/lead_sources/`, { params })).data;
};

export const postTalentLeadSource = async (data) => {
  return (await ax.post(`accounts/lead_sources/`, data)).data;
};

export const postLeadSource = async (data) => {
  return (await ax.post(`projects/lead_sources/`, data)).data;
};

export const patchLeadSource = async (id, data) => {
  return (await ax.patch(`projects/lead_sources/${id}/`, data)).data;
};

export const getTalentFilters = async (user__id) => {
  return (
    await ax.get(`accounts/custom_filters/`, {
      params: { user__id, page_size: 9999999 },
    })
  ).data;
};

export const postTalentFilter = async (data) => {
  return (await ax.post(`accounts/custom_filters/`, data)).data;
};

export const patchTalentFilter = async (id, data) => {
  return (await ax.patch(`accounts/custom_filters/${id}/`, data)).data;
};

export const deleteTalentFilter = async (id) => {
  return (await ax.delete(`accounts/custom_filters/${id}/`)).data;
};

export const getTalentColumns = async (user__id) => {
  const data = (
    await ax.get(`accounts/custom_table_columns/`, {
      params: { user__id, page_size: 1 },
    })
  ).data;

  if (data && Array.isArray(data.results)) {
    return data.results[0];
  }
};

export const postTalentColumn = async (data) => {
  return (await ax.post(`accounts/custom_table_columns/`, data)).data;
};

export const patchTalentColumn = async (id, data) => {
  return (await ax.patch(`accounts/custom_table_columns/${id}/`, data)).data;
};

export const getProjectFilters = async (user__id) => {
  return (
    await ax.get(`projects/custom_filters/`, {
      params: { user__id, page_size: 9999999 },
    })
  ).data;
};

export const postProjectFilter = async (data) => {
  return (await ax.post(`projects/custom_filters/`, data)).data;
};

export const patchProjectFilter = async (id, data) => {
  return (await ax.patch(`projects/custom_filters/${id}/`, data)).data;
};

export const deleteProjectFilter = async (id) => {
  return (await ax.delete(`projects/custom_filters/${id}/`)).data;
};

export const getProjectColumns = async (user__id) => {
  const data = (
    await ax.get(`projects/custom_table_columns/`, {
      params: { user__id, page_size: 1 },
    })
  ).data;

  if (data && Array.isArray(data.results)) {
    return data.results[0];
  }
};

export const postProjectColumn = async (data) => {
  return (await ax.post(`projects/custom_table_columns/`, data)).data;
};

export const patchProjectColumn = async (id, data) => {
  return (await ax.patch(`projects/custom_table_columns/${id}/`, data)).data;
};

export const getCompanyFilters = async (user__id) => {
  return (
    await ax.get(`companies/custom_filters/`, {
      params: { user__id, page_size: 9999999 },
    })
  ).data;
};

export const postCompanyFilter = async (data) => {
  return (await ax.post(`companies/custom_filters/`, data)).data;
};

export const patchCompanyFilter = async (id, data) => {
  return (await ax.patch(`companies/custom_filters/${id}/`, data)).data;
};

export const deleteCompanyFilter = async (id) => {
  return (await ax.delete(`companies/custom_filters/${id}/`)).data;
};

export const getCompanyColumns = async (user__id) => {
  const data = (
    await ax.get(`companies/custom_table_columns/`, {
      params: { user__id, page_size: 1 },
    })
  ).data;

  if (data && Array.isArray(data.results)) {
    return data.results[0];
  }
};

export const postCompanyColumn = async (data) => {
  return (await ax.post(`companies/custom_table_columns/`, data)).data;
};

export const patchCompanyColumn = async (id, data) => {
  return (await ax.patch(`companies/custom_table_columns/${id}/`, data)).data;
};

export const getContactFilters = async (user__id) => {
  return (
    await ax.get(`companies/contacts/custom_filters/`, {
      params: { user__id, page_size: 9999999 },
    })
  ).data;
};

export const postContactFilter = async (data) => {
  return (await ax.post(`companies/contacts/custom_filters/`, data)).data;
};

export const patchContactFilter = async (id, data) => {
  return (await ax.patch(`companies/contacts/custom_filters/${id}/`, data))
    .data;
};

export const deleteContactFilter = async (id) => {
  return (await ax.delete(`companies/contacts/custom_filters/${id}/`)).data;
};

export const getContactColumns = async (user__id) => {
  const data = (
    await ax.get(`companies/contacts/custom_table_columns/`, {
      params: { user__id, page_size: 1 },
    })
  ).data;

  if (data && Array.isArray(data.results)) {
    return data.results[0];
  }
};

export const postContactColumn = async (data) => {
  return (await ax.post(`companies/contacts/custom_table_columns/`, data)).data;
};

export const patchContactColumn = async (id, data) => {
  return (
    await ax.patch(`companies/contacts/custom_table_columns/${id}/`, data)
  ).data;
};

export const getPaymentDeadlines = async (params) => {
  return (await ax.get(`projects/contract_payment_deadline/`, { params })).data;
};

export const postPaymentDeadline = async (data) => {
  return (await ax.post(`projects/contract_payment_deadline/`, data)).data;
};

export const getInvoiceFrequencies = async (params) => {
  return (await ax.get(`projects/contract_invoice_frequency/`, { params }))
    .data;
};

export const postInvoiceFrequency = async (data) => {
  return (await ax.post(`projects/contract_invoice_frequency/`, data)).data;
};

export const getContractRateHistory = async (contract__id) => {
  return (
    await ax.get(`projects/contract_history/`, {
      params: { contract__id, page_size: 9999 },
    })
  ).data;
};

export const getPitcheds = async (project__id) => {
  return (
    await ax.get(`projects/pitched/`, {
      params: { project__id, page_size: 9999999 },
    })
  ).data;
};

export const postPitched = async (data) => {
  return (await ax.post(`projects/pitched/`, data)).data;
};

export const patchPitched = async (id, data) => {
  return (await ax.patch(`projects/pitched/${id}/`, data)).data;
};

export const deletePitched = async (id) => {
  return (await ax.delete(`projects/pitched/${id}/`)).data;
};

export const getSettingsEmail = async (user) => {
  const data = (
    await ax.get(`accounts/user_email_subscriptions/`, { params: { user } })
  ).data;

  if (data && Array.isArray(data.results) && data.results.length) {
    return data.results[0];
  } else {
    return {};
  }
};

export const postSettingsEmail = async (data) => {
  return (await ax.post(`accounts/user_email_subscriptions/`, data)).data;
};

export const patchSettingsEmail = async (id, data) => {
  return (await ax.patch(`accounts/user_email_subscriptions/${id}/`, data))
    .data;
};

export const postStatusHistory = async (data) => {
  return (await ax.post(`projects/status_history/`, data)).data;
};

export const assignProjectClockify = async (projectId, data) => {
  return (await ax.post(`projects/${projectId}/connect_clockify/`, data)).data;
};

export const createProjectClockify = async (projectId, data) => {
  return (await ax.post(`projects/${projectId}/add_to_clockify/`, data)).data;
};

export const postClockifyClient = async (data) => {
  return (await ax.post(`clockify/clients/add_to_clockify/`, data)).data;
};

export const getClockifyClients = async (params) => {
  return (await ax.get(`clockify/clients/`, { params })).data;
};

export const getClockifyTalents = async (params) => {
  return (await ax.get(`clockify/talents/`, { params })).data;
};

export const attachTalentToProjectClockify = async (projectId, data) => {
  return (await ax.post(`projects/${projectId}/add_talent_to_clockify/`, data))
    .data;
};

export const getProjectClokify = async (projectId) => {
  return (await ax.get(`clockify/projects/${projectId}/`)).data;
};

export const getClockifyGroups = async (params) => {
  return (await ax.get(`clockify/groups/`, { params })).data;
};

export const attachGroupToProjectClockify = async (projectId, data) => {
  return (await ax.post(`projects/${projectId}/add_group_to_clockify/`, data))
    .data;
};

export const assignCompanyClockify = async (companyId, data) => {
  return (await ax.post(`companies/${companyId}/connect_clockify/`, data)).data;
};

export const createCompanyClockify = async (companyId, data) => {
  return (await ax.post(`companies/${companyId}/add_to_clockify/`, data)).data;
};

export const assignTalentClockify = async (talentId, data) => {
  return (await ax.post(`accounts/${talentId}/connect_clockify/`, data)).data;
};

export const createTalentClockify = async (talentId, data) => {
  return (await ax.post(`accounts/${talentId}/add_to_clockify/`, data)).data;
};

export const getProjectContacts = async (projectId) => {
  const data = (await ax.get(`projects/contacts/?project__id=${projectId}`)).data;
  if (data && data.results) {
    return data.results;
  } else {
    return [];
  }
};

export const getContactsForCompany = async (companyId) => {
  const data = (await ax.get(`companies/contacts/?company_id=${companyId}`)).data;
  if (data && data.results) {
    return data.results;
  } else {
    return [];
  }
};

export const postProjectContact = async (data) => {
    return (await ax.post(`projects/contacts/`, data)).data;
};

export const patchProjectContact = async (id, data) => {
    return (await ax.patch(`projects/contacts/${id}/`, data)).data;
};

export const deleteProjectContact = async (id) => {
    return (await ax.delete(`projects/contacts/${id}/`)).data;
};

// V2 API

export const getProjectsV2 = async (params) => {
  return (await ax.get(`api/v2/projects`, { params })).data;
}

export const getProjectV2 = async (projectId) => {
  return (await ax.get(`api/v2/projects/${projectId}/`)).data;
};

export const postProjectV2 = async (data) => {
  return (await ax.post(`api/v2/projects/`, data)).data;
};

export const patchProjectV2 = async (projectId, data) => {
  return (await ax.patch(`api/v2/projects/${projectId}/`, data)).data;
};

export const getProjectCompanyV2 = async (projectId) => {
  return (await ax.get(`api/v2/projects/${projectId}/company_details/`)).data;
};

export const patchProjectCompanyV2 = async (projectId, data) => {
  return (await ax.patch(`api/v2/projects/${projectId}/company_details/`, data))
    .data;
};

export const getProjectDescriptionV2 = async (projectId) => {
  return (await ax.get(`api/v2/projects/${projectId}/description/`)).data;
};

export const patchProjectDescriptionV2 = async (projectId, data) => {
  return (await ax.patch(`api/v2/projects/${projectId}/description/`, data))
    .data;
};

export const getProjectTalentDescriptionV2 = async (projectId) => {
  return (await ax.get(`api/v2/projects/${projectId}/talent_description/`))
    .data;
};

export const patchProjectTalentDescriptionV2 = async (projectId, data) => {
  return (
    await ax.patch(`api/v2/projects/${projectId}/talent_description/`, data)
  ).data;
};

export const getProjectOwnersV2 = async (projectId) => {
  return (await ax.get(`api/v2/projects/${projectId}/stakeholders/owners/`))
    .data;
};

export const getProjectCeV2 = async (projectId) => {
  return (
    await ax.get(`api/v2/projects/${projectId}/stakeholders/client_engagement/`)
  ).data;
};

export const getProjectCesV2 = async (projectId) => {
  return (
    await ax.get(`api/v2/projects/${projectId}/stakeholders/project_ces`)
  ).data;
};

export const getProjectOpsV2 = async (projectId) => {
  return (await ax.get(`api/v2/projects/${projectId}/stakeholders/ops_owner/`))
    .data;
};

export const patchProjectOwnersV2 = async (projectId, data) => {
  return (
    await ax.patch(`api/v2/projects/${projectId}/stakeholders/owners/`, data)
  ).data;
};

export const patchProjectCeV2 = async (projectId, data) => {
  return (
    await ax.patch(
      `api/v2/projects/${projectId}/stakeholders/client_engagement/`,
      data
    )
  ).data;
};

export const patchProjectCesV2 = async (projectId, data) => {
  return (
    await ax.patch(
      `api/v2/projects/${projectId}/stakeholders/project_ces/`,
      data
    )
  ).data;
};

export const patchProjectOpsV2 = async (projectId, data) => {
  return (
    await ax.patch(`api/v2/projects/${projectId}/stakeholders/ops_owner/`, data)
  ).data;
};

export const deleteProjectOwnersV2 = async (projectId) => {
  return (await ax.delete(`api/v2/projects/${projectId}/stakeholders/owners/`))
    .data;
};

export const deleteProjectCeV2 = async (projectId) => {
  return (
    await ax.delete(
      `api/v2/projects/${projectId}/stakeholders/client_engagement/`
    )
  ).data;
};

export const deleteProjectOpsV2 = async (projectId) => {
  return (
    await ax.delete(`api/v2/projects/${projectId}/stakeholders/ops_owner/`)
  ).data;
};

export const getProjectFilesV2 = async (project__id) => {
  return (
    await ax.get(`api/v2/projects/files/`, {
      params: { project__id, page_size: 9999 },
    })
  ).data;
};

export const getProjectQualificationsV2 = async (project__id) => {
  return (
    await ax.get(`api/v2/projects/qualifications/`, {
      params: { project__id, page_size: 9999 },
    })
  ).data;
};

export const getProjectNotesV2 = async (params) => {
  return (await ax.get(`api/v2/projects/notes/`, { params })).data;
};

export const deleteProjectNoteV2 = async (projectId, noteType, id) => {
  return (await ax.delete(`api/v2/projects/${projectId}/notes/${noteType}/${id}/`)).data;
};

export const deleteProjectFileV2 = async (id) => {
  return (await ax.delete(`api/v2/projects/files/${id}`)).data;
};

export const getProjectPreferencesV2 = async (projectId) => {
  return (await ax.get(`api/v2/projects/${projectId}/preferences/`)).data;
};

export const patchProjectPreferencesV2 = async (projectId, data) => {
  return (await ax.patch(`api/v2/projects/${projectId}/preferences/`, data))
    .data;
};

export const patchProjectNoteV2 = async (projectId, noteType, id, data) => {
  return (await ax.patch(`api/v2/projects/${projectId}/notes/${noteType}/${id}/`, data)).data;
};

export const getCandidatesV2 = async (params) => {
  return (await ax.get(`api/v2/projects/candidates`, { params })).data;
};

export const getCandidateTalentInfo = async (candidateId) => {
  return (await ax.get(`api/v2/projects/candidate/${candidateId}/talent_info/`))
    .data;
};

export const patchCandidateV2 = async (candidateId, data) => {
  return (await ax.patch(`api/v2/projects/candidates/${candidateId}/`, data))
    .data;
};

export const bulkUpdateCandidatesV2 = async (candidateIds, data) => {
  return (await ax.put(`api/v2/projects/bulk_update_candidates?candidate_ids=${candidateIds.join(',')}`, data))
    .data;
};

export const bulkMoveCandidatesV2 = async (candidateIds, data) => {
  return (await ax.put(`api/v2/projects/bulk_move_candidates?candidate_ids=${candidateIds.join(',')}`, data))
    .data;
};

export const deleteCandidate = async (candidateId) => {
  return (await ax.delete(`api/v2/projects/candidates/${candidateId}/`)).data;
};

export const getCandidateProjectsV2 = async (params) => {
  return (await ax.get(`/api/v2/projects/candidates/projects`, { params }))
    .data;
};

export const postCandidateV2 = async (data) => {
  return (await ax.post(`api/v2/projects/candidates/`, data)).data;
};

export const deleteCandidatesV2 = async (params) => {
  return (await ax.delete(`api/v2/projects/candidates`, { params })).data;
};

export const getCandidateProjectNotes = async (params) => {
  return (await ax.get(`api/v2/projects/notes`, { params })).data;
};

export const postProjectNoteV2 = async (data) => {
  return (await ax.post(`api/v2/projects/notes/`, data)).data;
};

export const getTalentAgreements = async (talentId) => {
  return (await ax.get(`/accounts/talent_agreements/${talentId}`)).data;
};

export const getTalentDiff = async (idA, idB) => {
  return (await ax.get(`/accounts/talent_diff/?final=${idA}&to_delete=${idB}`))
    .data;
};

export const postMergeTalents = async (idA, idB, data) => {
  return (
    await ax.post(
      `/accounts/merge_talents/?final=${idA}&to_delete=${idB}`,
      data
    )
  ).data;
};

export const getSenioritiesList = async () => {
  return (await ax.get('seniorities/')).data;
}

export const postProjectSeniorityV2 = async (data) => {
  return (await ax.post(`api/v2/projects/seniorities/`, data)).data;
}

export const patchProjectSeniorityV2 = async (seniorityId, data) => {
  return (await ax.patch(`api/v2/projects/seniorities/${seniorityId}/`, data)).data;
}

export const deleteProjectSeniorityV2 = async (seniorityId) => {
  return (await ax.delete(`api/v2/projects/seniorities/${seniorityId}/`)).data;
}

export const getTalentNotesV2 = async (params) => {
  return (await ax.get(`api/v2/accounts/notes/`, { params })).data;
};

export const deleteTalentNoteV2 = async (talentId, noteType, id) => {
  return (await ax.delete(`api/v2/accounts/${talentId}/notes/${noteType}/${id}/`)).data;
};

export const patchTalentNoteV2 = async (talentId, noteType, id, data) => {
  return (await ax.patch(`api/v2/accounts/${talentId}/notes/${noteType}/${id}/`, data)).data;
};

export const getTalentQualificationsV2 = async (user__user_id, params) => {
  return (await ax.get(`api/v2/accounts/qualifications/`, {
      params: { user__user_id, ...params }
  })).data;
};

export const deleteTalentQualificationV2 = async (id) => {
  return (await ax.delete(`api/v2/accounts/qualifications/${id}/`)).data;
};

export const patchTalentQualificationV2 = async (id, data) => {
  return (await ax.patch(`api/v2/accounts/qualifications/${id}/`, data)).data;
};

export const getTalentDescriptionV2 = async (id) => {
  return (await ax.get(`api/v2/accounts/${id}/description/`)).data;
};

export const patchTalentDescriptionV2 = async (id, data) => {
  return (await ax.patch(`api/v2/accounts/${id}/description/`, data)).data;
};

export const getTalentPreferencesV2 = async (id) => {
  return (await ax.get(`api/v2/accounts/${id}/preferences/`)).data;
};

export const patchTalentPreferencesV2 = async (id, data) => {
  return (await ax.patch(`api/v2/accounts/${id}/preferences/`, data)).data;
};

export const getTalentOnboardingV2 = async (id) => {
  return (await ax.get(`api/v2/accounts/${id}/onboarding/`)).data;
};

export const patchTalentOnboardingV2 = async (id, data) => {
  return (await ax.patch(`api/v2/accounts/${id}/onboarding/`, data)).data;
};

export const getTalentFilesV2 = async (_, params) => {
  return (await ax.get(`api/v2/accounts/files/`, { params })).data;
};

export const deleteTalentFileV2 = async (id) => {
  return (await ax.delete(`api/v2/accounts/files/${id}/`)).data;
};

export const getAccountV2 = async (id) => {
  return (await ax.get(`api/v2/accounts/${id}/`)).data;
};

export const patchAccountV2 = async (id, data) => {
  return (await ax.patch(`api/v2/accounts/${id}/`, data)).data;
};

export const deleteAccountV2 = async (id) => {
  return (await ax.delete(`api/v2/accounts/${id}/`)).data;
};

export const getTalentProjectsV2 = async (params) => {
  return (await ax.get(`api/v2/accounts/projects/`, { params })).data;
};

export const getAccountContactsV2 = async (params) => {
  return (await ax.get(`api/v2/accounts/contacts/`, { params })).data;
}

export const postAccountContactV2 = async (data) => {
  return (await ax.post(`api/v2/accounts/contacts/`, data)).data;
}

export const patchAccountContactV2 = async (id, data) => {
  return (await ax.patch(`api/v2/accounts/contacts/${id}`, data)).data;
}

export const deleteAccountContactV2 = async (id) => {
  return (await ax.delete(`api/v2/accounts/contacts/${id}`)).data;
}

export const getContactNotesV2 = async (params) => {
  return (await ax.get(`api/v2/accounts/contact/notes/`, { params })).data;
}

export const postContactNoteV2 = async (data) => {
  return (await ax.post(`api/v2/accounts/contact/notes/`, data)).data;
}

export const patchContactNoteV2 = async (id, data) => {
  return (await ax.patch(`api/v2/accounts/contact/notes/${id}`, data)).data;
}

export const deleteContactNoteV2 = async (id) => {
  return (await ax.delete(`api/v2/accounts/contact/notes/${id}`)).data;
}

export const createTalentFromContactV2 = async (id) => {
  return (await ax.post(`api/v2/accounts/contacts/${id}/create_talent/`)).data;
}

export const getContactOverviewV2 = async (id) => {
  return (await ax.get(`api/v2/accounts/${id}/contacts/overview/`)).data;
}

export const patchContactOverviewV2 = async (id, data) => {
  return (await ax.patch(`api/v2/accounts/${id}/contacts/overview/`, data)).data;
}

export const getContactsV2 = async (params) => {
  // params can include search, type
  return (await ax.get(`api/v2/accounts/contact_users/`, { params })).data;
}

// generic GET method for response.next URLs
export const getNext = async (url) => {
  return (await ax.get(url)).data;
}

export const getTalentAttachedFileDownloadUrl = (id) => {
  return `${ax.defaults.baseURL}accounts/download_file/${id}/`;
}
