import React, {useEffect, useState} from "react";
import $ from "strings/talent";
import {Checkbox, Input, RateInput} from "components/Form";
import {
  FormErrorMessage,
  Label,
  SettingsActionBar,
  Sublabel
} from "../components";
import {Form, Formik} from "formik";
import {patchTalentTalent} from "../../../utils/api";
import AlertError from "../../../components/AlertError";
import * as yup from "yup";
import {useAlert} from "react-alert";


const RATE_CONSIDERATIONS_OPTIONS = [
  {label: $.passion_project_option, value: 'PassionProject'},
  {label: $.longer_contract_option, value: 'LongerContract'},
  {label: $.more_hours_option, value: 'WeeklyWork'}
];


const Availability = ({ talentData, setTalentData }) => {
  const [userId, setUserId] = useState();
  const [initialValues, setInitialValues] = useState({
    yearsOfExperience: 0,
    minimumRate: 0,
    rateConsiderations: ''
  });
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (!talentData) {
      return;
    }
    setInitialValues({
      yearsOfExperience: talentData.years_of_experience,
      minimumRate: talentData.minimum_rate,
      rateConsiderations: talentData.rate_flexibility
    });
    setUserId(talentData.user?.id);
  }, [talentData]);

  const alert = useAlert();

  const updateTalent = async (values) => {
    const data = {
      years_of_experience: values.yearsOfExperience,
      minimum_rate: values.minimumRate,
      rate_flexibility: values.rateConsiderations
    };
    try {
      await patchTalentTalent(userId, data);
      setInitialValues(values);
    } catch (e) {
      console.error({ e, data });
      alert.error(<AlertError error="Something went wrong. Please try again, or contact us if the issue continues." />);
    }
  };

  const validationSchema = yup.object().shape({
    yearsOfExperience: yup
      .number()
      .required("Enter your years of experience.")
      .min(0, "Years of experience cannot be less than zero."),
    minimumRate: yup
      .number()
      .required("Enter your minimum hourly rate.")
      .min(0, "Your rate cannot be less than zero.")
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={() => {}}
      enableReinitialize={true}
    >
      {({
        setFieldValue,
        values,
        touched,
        setTouched,
        setFieldTouched,
        errors,
        setFieldError,
        validateForm
      }) => {
        const onChange = async (e) => {
          let { name, value } = e.target;
          if ((name === 'yearsOfExperience' || name === 'minimumRate') && !!value) {
            value = parseInt(value);
          }
          await setFieldValue(name, value);
          await setFieldTouched(name, value !== initialValues[name]);
          if (errors[name]) {
            setFieldError(name, null);
          }
        };

        const onCheckboxClick = async (fieldName, option, value) => {
          const currentValues = values[fieldName];
          let currentValuesArr = currentValues?.length ? currentValues.split('|') : [];
          if (value === true && currentValuesArr.indexOf(option) === -1) {
            currentValuesArr.push(option);
            if (errors[fieldName]) {
              setFieldError(fieldName, null);
            }
          } else if (value === false && currentValuesArr.indexOf(option) > -1) {
            currentValuesArr.splice(currentValuesArr.indexOf(option), 1);
          }
          const newValues = currentValuesArr.sort().join('|');
          await setFieldValue(fieldName, newValues);
          await setFieldTouched(fieldName, newValues !== initialValues[fieldName]);
        };

        return (
          <Form className="form">
            <div className="mb-2">
              <Label>{$.experience_label}</Label>
              <div className="w-full sm:w-1/2">
                <Input
                  type="number"
                  name="yearsOfExperience"
                  maxLength="100"
                  placeholder={$.amount_placeholder}
                  onChange={onChange}
                  value={values.yearsOfExperience}
                  error={errors.yearsOfExperience}
                  fontSize={'text-sm sm:text-base'}
                />
              </div>
              <FormErrorMessage error={errors.yearsOfExperience}/>
            </div>
            <div className="mb-2">
              <Label
                className="w-full sm:w-1/2 font-normal text-darkest-navy text-sm sm:text-base">{$.rate_label}</Label>
              <div className="w-full sm:w-1/2">
                <RateInput
                  name="minimumRate"
                  placeholder={$.amount_placeholder}
                  onChange={onChange}
                  value={values.minimumRate}
                  error={errors.minimumRate}
                  fontSize={'text-sm sm:text-base'}
                  children={<span
                    className="absolute right-3 top-2 bottom-2">{$.per_hr_label}</span>}
                />
              </div>
              <FormErrorMessage error={errors.minimumRate}/>
            </div>
            <div className="mb-2">
              <Label className="font-normal text-darkest-navy text-sm sm:text-base">{$.rate_considerations_label}</Label>
              <Sublabel>{$.select_all_sublabel}</Sublabel>
              {RATE_CONSIDERATIONS_OPTIONS.map((option) =>
                <Checkbox
                  outlined
                  key={option.value}
                  value={!!values['rateConsiderations']?.includes(option.value)}
                  onChange={(v) => onCheckboxClick('rateConsiderations', option.value, v)}
                >
                  <div className="ml-1 my-1 font-sm text-darkest-navy font-lato">{option.label}</div>
                </Checkbox>
              )}
              <FormErrorMessage error={errors.rateConsiderations}/>
            </div>
            {Object.values(touched).some(Boolean) && (
              <SettingsActionBar
                cancelDisabled={isUpdating}
                saveDisabled={isUpdating}
                onCancel={async () => {
                  for (let [key, value] of Object.entries(initialValues)) {
                    await setFieldValue(key, value);
                  }
                  await setTouched({});
                }}
                onSave={async () => {
                  setIsUpdating(true);
                  const errors = await validateForm();
                  if (!Object.keys(errors).length) {
                    await updateTalent(values);
                  }
                  setIsUpdating(false);
                }}
              />
            )}
          </Form>
        )
      }}
    </Formik>);
}

export default Availability;
