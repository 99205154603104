import React, {useEffect} from 'react';

const PointedTooltip = ({ children, msg, onClick, className, hidden, pointed=true, translate='-translate-x-24' }) => {
  let translation;

  return (
    <>{hidden === true ? (
      <>{children}</>
    ) : (
      <div
        className="relative"
      >
        {children}
        <div
          onClick={onClick}
          className={`absolute text-kasmir bg-white p-1.5 border border-lightest-grey bottom-full mb-4 w-60 text-xs rounded-lg text-center transform ${translate} ${className}`}
        >
          {msg}
          {pointed && (
            <div
              className="absolute w-4 h-4 bg-white rounded-br rounded-tl-3xl border-b border-r border-geyser left-1/2 -translate-x-1/2 -translate-y-px transform rotate-45 "
            ></div>
          )}
        </div>
      </div>
    )}</>
  );
};

export default PointedTooltip;
