import React, {useEffect, useState} from "react";
import $ from "strings/talent";
import { EditBtn } from "components/Buttons";
import AboutMeForm from "./AboutMeForm";
import { urlify } from "utils/str";

const AboutMe = ({ talentData, setTalentData, isPublicProfile, setOpenAboutMe }) => {
  const [modal, setModal] = useState();
  const { about_me } = talentData;

  useEffect(() => {
    if (setOpenAboutMe) {
      setOpenAboutMe(() => () => setModal('aboutMe'));
    }
  }, [setOpenAboutMe]);

  return (
    <div className="bg-white rounded-lg p-4 text-midnight gap-y-4 flex flex-col">
      <div className="flex">
        <div className="text-lg font-bold">{$.about_me_title}</div>
        <div className="ml-auto" hidden={isPublicProfile}>
          <EditBtn className="w-full" onClick={() => setModal('aboutMe')} />
        </div>
      </div>
      <div className="text-sm">
      {about_me && <div dangerouslySetInnerHTML={{ __html: urlify(about_me) }} />}
      </div>
      {modal === "aboutMe" &&
        <AboutMeForm
          onClose={() => setModal()}
          talentData={talentData}
          setTalentData={setTalentData}
        />
      }
    </div>
  )
};

export default AboutMe;
